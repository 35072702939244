import React, { useCallback, useEffect, useRef, useState } from 'react';
import { createRoot } from 'react-dom/client';
import Close from '@mui/icons-material/Close';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';

type ConfirmTextType = React.ReactNode;

type ShowFn = (
  title?: ConfirmTextType,
  description?: ConfirmTextType,
  options?: {
    confirm?: string;
    isAlert?: boolean;
    isClose?: boolean;
  },
) => Promise<boolean>;

type ConfirmService = { show: ShowFn };
type ResolveFn = (value: boolean | PromiseLike<boolean>) => void;

/**
 * @description Confirm Simple Service
 * @Class
 * @category Utils
 * @subcategory confirm
 * @param {object} props
 * @param {function} props.refShow
 * @returns {JSX.Element}
 */
const Confirm = ({ refShow }: { refShow: (showFn: ShowFn) => void }) => {
  const [state, setIsOpen] = useState<{
    isOpen: boolean;
    text: ConfirmTextType;
  }>({
    isOpen: false,
    text: '변경?',
  });
  const [isClose, setIsClose] = useState(true);
  const [confirm, setConfirm] = useState('확인');
  const [title, setTitle] = useState<ConfirmTextType>('제목');
  const resRef = useRef<ResolveFn | null>(null);

  const handleCancel = useCallback((event?: {}, reason?: 'backdropClick' | 'escapeKeyDown') => {
    if (reason === 'backdropClick') return;
    setIsOpen((prev) => ({ ...prev, isOpen: false }));
    resRef.current?.(false);
    resRef.current = null;
  }, []);

  const handleConfirm = useCallback(() => {
    setIsOpen((prev) => ({ ...prev, isOpen: false }));
    resRef.current?.(true);
    resRef.current = null;
  }, []);

  const showCb: ShowFn = useCallback((title, text, options) => {
    if (text) {
      setTitle(title);
      if (options?.confirm) {
        setConfirm(options.confirm);
      } else {
        setConfirm('확인');
      }
      if (options?.isClose) {
        setIsClose(false);
      } else {
        setIsClose(true);
      }
      setIsOpen({ text, isOpen: true });
    } else {
      setIsOpen((prev) => ({ ...prev, isOpen: true }));
    }
    return new Promise((res) => {
      resRef.current = res;
    });
  }, []);

  useEffect(() => {
    refShow(showCb);
  }, [showCb]);

  return (
    <Dialog hideBackdrop={false} open={state.isOpen} onClose={handleCancel} sx={{ zIndex: 999999 }}>
      <div
        style={{
          width: '300px',
          // height: '204px',
        }}
      >
        <DialogTitle>
          <IconButton
            onClick={handleCancel}
            style={{
              position: 'absolute',
              right: '0.3em',
              top: '0.3em',
              color: '#9e9e9e',
            }}
          >
            {isClose ? <Close fontSize="small" /> : ''}
          </IconButton>
        </DialogTitle>

        <DialogContent>
          {title && (
            <p className="popup-content" style={{ fontSize: '16px', fontWeight: '500' }}>
              {title}
            </p>
          )}
          <p className="popup-content">{state.text}</p>
        </DialogContent>
        <DialogActions
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            // height: '60px',
          }}
        >
          <div className="popup-small-button-wrap btn-wrap">
            <button type="button" className="btn" onClick={handleConfirm}>
              확인
            </button>
          </div>
        </DialogActions>
      </div>
    </Dialog>
  );
};

Confirm.create = () => {
  const containerElement = document.createElement('div');
  document.body.appendChild(containerElement);

  const root = createRoot(containerElement);
  const ref: ConfirmService = { show: null as any };

  root.render(
    <Confirm
      refShow={(showFn) => {
        ref.show = showFn;
      }}
    />,
  );
  return ref;
};

const confirmSimpleService = Confirm.create();
export default confirmSimpleService;
