import { createRoot } from 'react-dom/client';
import { BrowserRouter as Router } from 'react-router-dom';

import { Provider } from 'react-redux';
import store from 'redux/store';
import { persistStore } from 'redux-persist';
import { PersistGate } from 'redux-persist/integration/react';
import { CookiesProvider } from 'react-cookie';
import App from './pages/App';
import reportWebVitals from './reportWebVitals';
import LangProvider from './lang/LangProvider';
import ModalProvider from './components/common/providers/ModalProvider';
import './assets/css/reset.css';
import './assets/css/common.css';

export const persistor = persistStore(store);

const root = createRoot(document.getElementById('root') as HTMLElement);

root!.render(
  <CookiesProvider>
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <LangProvider>
          <ModalProvider>
            <Router>
              <App />
            </Router>
          </ModalProvider>
        </LangProvider>
      </PersistGate>
    </Provider>
  </CookiesProvider>,
);

reportWebVitals();
