/* eslint-disable consistent-return */
/* eslint-disable @typescript-eslint/no-shadow */
/* eslint-disable no-continue */
/* eslint-disable @typescript-eslint/no-inferrable-types */
/* eslint-disable no-useless-escape */
import moment from 'moment';
import { SIREN_POPUP_URL, REType, adStatusGbn } from 'configs/configs';
import { SSO_PATHS } from 'utils/path';
import _ from 'lodash';
import { formatDate } from './date';

/**
 * @description 로컬스토리지에 저장 & 불러오기
 * @description locStore(key): 해당 키의 값이 리턴됨
 * @description locStore(key, value): 해당 키에 값이 저장됨
 * @Class
 * @category Utils
 * @subcategory localStorage
 * @param key
 * @param value
 * @returns {any}
 */
export const locStore = (key: string, value?: object | string) => {
  if (value) {
    localStorage.setItem(key, typeof value === 'object' ? JSON.stringify(value) : value);
    return true;
  }
  const val = localStorage.getItem(key);
  if (val !== null) {
    try {
      return JSON.parse(val);
    } catch (e) {
      if (val.indexOf('object Object') > -1) {
        localStorage.removeItem(key);
        return null;
      }
      return val;
    }
  }
  return null;
};

/**
 * @description 로컬스토리지에 저장된 값 삭제
 * @Class
 * @category Utils
 * @subcategory localStorage
 * @param key
 * @returns {any}
 */
export const rmLocStore = (key: string) => {
  try {
    localStorage.removeItem(key);
  } catch (e) {
    /* empty */
  }
};

/**
 * @description only number
 * @Class
 * @category Utils
 * @subcategory number
 * @param {string} value
 * @returns {string}
 */
export function onlyNumber(value: string): string {
  return value.replace(/[^0-9]/g, '');
}

/**
 * @description makeSirenForm
 * @Class
 * @category Utils
 * @subcategory form
 * @param {string} target
 * @param {string} reqInfo
 * @param {string} retUrl
 * @returns {boolean}
 */
export const makeSirenForm = (target: string, { reqInfo, retUrl }: { reqInfo: string; retUrl: string }): boolean => {
  const form = document.createElement('form');
  form.setAttribute('method', 'post');
  form.setAttribute('action', SIREN_POPUP_URL);
  form.setAttribute('target', target);

  const reqInfoField = document.createElement('input');
  reqInfoField.setAttribute('type', 'hidden');
  reqInfoField.setAttribute('name', 'reqInfo');
  reqInfoField.setAttribute('value', reqInfo);
  form.appendChild(reqInfoField);

  const retUrlField = document.createElement('input');
  retUrlField.setAttribute('type', 'hidden');
  retUrlField.setAttribute('name', 'retUrl');
  retUrlField.setAttribute('value', retUrl);
  form.appendChild(retUrlField);

  const versionField = document.createElement('input');
  versionField.setAttribute('type', 'hidden');
  versionField.setAttribute('name', 'verSion');
  versionField.setAttribute('value', '2');
  form.appendChild(versionField);

  document.body.appendChild(form);
  form.submit();

  return true;
};

/**
 * @description make form
 * @Class
 * @category Utils
 * @subcategory form
 * @param {string} url
 * @param {string} data
 * @returns {boolean}
 */
export const makeForm = (url: string, data: { name: string; value: string }[]): boolean => {
  const form = document.createElement('form');
  form.setAttribute('method', 'post');
  form.setAttribute('action', url);
  // form.setAttribute('target', target);

  data.forEach((item) => {
    const field = document.createElement('input');
    field.setAttribute('type', 'hidden');
    field.setAttribute('name', item.name);
    field.setAttribute('value', item.value);
    form.appendChild(field);
  });

  document.body.appendChild(form);
  form.submit();

  return true;
};

/**
 * @description 면적을 평으로 변경
 * @Class
 * @category Utils
 * @subcategory 면적
 * @param {number} num 면적
 * @param {string} type
 * @returns {string} 평
 */
export const squareToPyeong = (num: number, type?: string): number => {
  const value: number = num * 0.3025;
  return Number(value.toFixed(2));
};

/**
 * @description 면적을 평으로, 평을 면적으로 변경
 * @Class
 * @category Utils
 * @subcategory 면적
 * @param {number} num 면적
 * @param {string} type 'toPyeong', 'toSquare'
 * @returns {number} 면적, 평
 */
export const convertArea = (num: number, type?: string): number => {
  let value: number;
  if (type === 'toPyeong') {
    value = num * 0.3025;
  } else {
    value = num * 3.3058;
  }
  return Number(value.toFixed(2));
};

/**
 * @description 메인 뷰 이동
 * @Class
 * @category Utils
 * @subcategory mobile
 * @param tabIndex 탭 index
 * @param parameter 메인 뷰 param
 * @returns {void}
 */
export const moveMain = (tabIndex: number, parameter: string) => {
  const payload = JSON.stringify({ tabIndex, parameter });

  if (window.webkit) {
    window.webkit.messageHandlers.moveTab.postMessage(payload);
  } else if (window.Android) {
    window.Android.moveTab(payload);
  }
};

/**
 * @description 웹뷰 닫기
 * @Class
 * @category Utils
 * @subcategory mobile
 * @returns {void}
 */
export const onClose = () => {
  if (window.webkit) {
    window.webkit.messageHandlers.close.postMessage('');
  } else if (window.Android) {
    window.Android.close('');
  }
};

/**
 * @description 매물구분에 따른 main_cd가져오기
 * @Class
 * @category Utils
 * @subcategory 매물
 * @param {string} offegGbn 매물구분
 * @returns {string} main_cd
 */
export const getOfferGroup = (offegGbn: string): string => {
  const _mainCD = REType.map((item) => item.offer_types)
    .flat()
    .find((item) => item.sub_cd === offegGbn)?.main_cd;
  const _group = _mainCD === 'A' || _mainCD === 'B' ? 'apartment_group' : 'nonapartment_group';
  return _group;
};

/**
 * @description 매물코드에 따른 매물종류명 가져오기
 * @Class
 * @category Utils
 * @subcategory 매물
 * @param {string} offegGbn
 * @returns {string}
 */
export const getOfferGbnStr = (offegGbn: string): string => {
  return (
    REType.map((item) => item.offer_types)
      .flat()
      .find((item) => item.sub_cd === offegGbn)?.sub_nm || ''
  );
};

/**
 * @description 매물상태에 따른 상태명 가져오기
 * @Class
 * @category Utils
 * @subcategory 매물
 * @param {string | undefined} statusGbn
 * @returns {string}
 */
export const getStatusGbnStr = (statusGbn: string | undefined): string => {
  return adStatusGbn.find((item) => item.cd === statusGbn)?.name || '';
};

/**
 * @description 0을 pad
 * @Class
 * @category Utils
 * @subcategory number
 * @param {string} string 문자열
 * @param {number} pow length
 * @param {string} pad 채우는 pad 문자열
 * @returns {string} 반환된 pad 문자열
 */
export function zeroPad(string: number | string = '', pow: number = 0, pad: string = '0'): string {
  let result = String(string);
  const padString = String(pad);

  for (let i = pow - result.length; i > 0; i--) {
    result = padString + result;
  }

  return result;
}

/**
 * @description 파일이름 가져오기
 * @Class
 * @category Utils
 * @subcategory file
 * @param {string} orginName 업로드할 파일명
 * @returns {string} 파일 확장자
 */
export const getFileName = (orginName: string): string => {
  const _lastDot = orginName.lastIndexOf('.');
  return orginName.substring(0, _lastDot).toLowerCase();
};

/**
 * @description 파일확장자 가져오기
 * @Class
 * @category Utils
 * @subcategory file
 * @param {string} orginName 업로드할 파일명
 * @returns {string} 파일 확장자
 */
export const getFileExtentions = (orginName: string): string => {
  const lastIndex = orginName.lastIndexOf('.');
  if (lastIndex < 0) {
    return '';
  }
  return orginName.substring(lastIndex + 1).toLowerCase();
};

/**
 * @description 파일 확장자체크
 * @Class
 * @category Utils
 * @subcategory file
 * @param {string} name 파일 full name
 * @returns {boolean}
 */
export const fileExtensionValidFunc = (name: string, type?: string): boolean => {
  const ALLOW_EXTENSION =
    type === 'profile' ? 'jpg,jpeg,png,gif,svg' : 'jpg,jpeg,png,gif,svg,pdf,dox,docx,hwp,xlsx,xls';
  const extension = getFileExtentions(name);
  console.log('extension===>', extension);
  if (!(ALLOW_EXTENSION.indexOf(extension) > -1) || extension === '') {
    return false;
  }
  return true;
};

/**
 * @description 시간 표시
 * @Class
 * @category Utils
 * @subcategory time
 * @param {string} timeString
 * @param {Date} diffTime
 * @returns {string}
 */
export function getTimeDifference(timeString: string, diffTime?: Date) {
  const currentTime = diffTime || new Date();
  const inputTime = new Date(timeString);
  const diffInSeconds = Math.floor((currentTime.getTime() - inputTime.getTime()) / 1000);

  const rtf = new Intl.RelativeTimeFormat('ko-KR', { numeric: 'auto' });

  if (diffInSeconds < 60) {
    // return rtf.format(-diffInSeconds, 'second');
    return '지금 막';
  }
  if (diffInSeconds < 3600) {
    return rtf.format(-Math.floor(diffInSeconds / 60), 'minute');
  }
  if (diffInSeconds < 86400) {
    return rtf.format(-Math.floor(diffInSeconds / 3600), 'hour');
  }
  if (diffInSeconds < 604800) {
    return rtf.format(-Math.floor(diffInSeconds / 86400), 'day');
  }
  return inputTime.toLocaleString('ko-KR', {
    year: '2-digit',
    month: '2-digit',
    day: '2-digit',
    hour: '2-digit',
    minute: '2-digit',
    hour12: false,
  });
}

/**
 * @description 사업자 번호 유효성 체크
 * @Class
 * @category Utils
 * @subcategory number
 * @param {string} number
 * @returns {boolean}
 */
export const checkBizNumber = (number: string): boolean => {
  const numberMap = number
    .replace(/-/gi, '')
    .split('')
    .map((d) => {
      return parseInt(d, 10);
    });

  if (numberMap.length === 10) {
    const keyArr = [1, 3, 7, 1, 3, 7, 1, 3, 5];
    let chk = 0;

    keyArr.forEach((d, i) => {
      chk += d * numberMap[i];
    });

    chk += parseInt(String((keyArr[8] * numberMap[8]) / 10), 10);
    // console.log(chk);
    return Math.floor(numberMap[9]) === (10 - (chk % 10)) % 10;
  }

  return false;
};

/**
 * @description 숫자를 한글로 변환
 * @Class
 * @category Utils
 * @subcategory number
 * @param {number | string} num
 * @returns {string}
 */
export const numTohan = (num: number | string) => {
  if (_.isNaN(num)) return '-';
  num = `${parseInt(`${num}`.replace(/[^0-9]/g, ''), 10)}`;
  if (num === '0') return '영';
  const number = ['영', '일', '이', '삼', '사', '오', '육', '칠', '팔', '구'];
  const unit = ['', '만', '억', '조'];
  const smallUnit = ['천', '백', '십', ''];
  const result = [];
  const unitCnt = Math.ceil(num.length / 4);
  num = num.padStart(unitCnt * 4, '0');
  const regexp = /[\w\W]{4}/g;
  const array = num.match(regexp);
  for (let i = array!.length - 1, unitCnt = 0; i >= 0; i--, unitCnt++) {
    const hanValue = _makeHan(array![i]);
    if (hanValue === '') continue;
    result.unshift(hanValue + unit[unitCnt]);
  }

  function _makeHan(text: string) {
    let str = '';
    for (let i = 0; i < text.length; i++) {
      const num2: any = text[i];
      if (num2 === '0') continue;
      str += number[num2] + smallUnit[i];
    }
    return str;
  }
  return result.join('');
};

/**
 * @description 글쓴시간과 현재시간을 체크하여 표기법대로 변환
 * @Class
 * @category Utils
 * @subcategory time
 * @param {Date} currTime
 * @param {Date} diffTime
 * @returns {string}
 */
export const timeDisplay = (currTime?: Date, diffTime?: Date | string) => {
  let dispTime;
  if (moment.duration(moment(currTime).diff(moment(diffTime))).asDays() < 1) {
    if (moment.duration(moment(currTime).diff(moment(diffTime))).asHours() < 1) {
      if (moment.duration(moment(currTime).diff(moment(diffTime))).asSeconds() < 60) {
        dispTime = `지금 막`;
      } else {
        dispTime = `${moment
          .duration(moment(currTime).diff(moment(diffTime)))
          .asMinutes()
          .toFixed()}분 전`;
      }
    } else {
      dispTime = `${moment
        .duration(moment(currTime).diff(moment(diffTime)))
        .asHours()
        .toFixed()}시간 전`;
    }
  } else {
    dispTime = `${formatDate(diffTime)}`;
  }
  return dispTime;
};

/**
 * @description 콤마제거 utils
 * @Class
 * @category Utils
 * @subcategory number
 * @param {string | number | null} s
 * @returns {string}
 */
export function rmComma(s: string | number | null): string {
  const rtn = String(s).replace(/,/gi, '');
  if (_.isNaN(Number(rtn))) {
    if (rtn === '-' || rtn === '0') {
      return rtn;
    }
    return '0';
  }
  return rtn;
}

/**
 * @description a tag로 변환
 * @Class
 * @category Utils
 * @subcategory etc
 * @param {string} matched
 * @returns {string}
 */
export const replaceURLWithAtag = (matched: string): string => {
  let withProtocol = matched;
  let newStr: string;
  if ((matched && withProtocol.startsWith('www')) || withProtocol.startsWith('WWW')) {
    withProtocol = `http://${matched}`;
    newStr = `<a href="${withProtocol}" target="_blank" style="text-decoration: underline" rel="noopener noreferrer">
      ${matched}
    </a>`;
    return newStr;
  }
  if (matched && (withProtocol.startsWith('http') || withProtocol.startsWith('https'))) {
        newStr = `<a href="${matched}" target="_blank" style="text-decoration: underline" rel="noopener noreferrer">
      ${matched}
    </a>`;
    return newStr;
  }
  return matched;
};

/**
 * @description s3 숫자 체크
 * @Class
 * @category Utils
 * @subcategory etc
 * @param {File} file
 * @returns {boolean}
 */
export const checkForS3Chars = (file: File): boolean => {
  const invalidChars = ['<', '>', ':', '"', '/', '\\', '|', '?', '*', '`', ']', '['];
  for (let i = 0; i < invalidChars.length; i++) {
    if (file.name.indexOf(invalidChars[i]) !== -1) {
      // alert(`파일이름에 사용할 수 없는 문자를 포함하고 있습니다. ${invalidChars[i]}`);
      return false;
    }
  }
  return true;
};

/**
 * @description parse jwt
 * @Class
 * @category Utils
 * @subcategory etc
 * @param {string} token
 * @returns {any}
 */
export const parseJwt = (token: string) => {
  const base64Url = token?.split('.')[1];
  const base64 = base64Url?.replace(/-/g, '+').replace(/_/g, '/');
  const jsonPayload = decodeURIComponent(
    atob(base64)
      .split('')
      .map((c) => {
        return `%${`00${c.charCodeAt(0).toString(16)}`.slice(-2)}`;
      })
      .join(''),
  );

  return JSON.parse(jsonPayload);
};
