import { lazy } from 'react';
import { PATHAUTH, PATHLOCALROUP, PATHCOAGENCY, PATHMYGROUP, PATHCOMMON, PATHMYINFO, PATHALLPOSTS } from 'utils/path';
import { protectedRouteObject } from '../@types/props';

const Search = lazy(() => import('pages/default/Search'));

const broker = {
  AuthNew: lazy(() => import('pages/agentcert/Auth')),
  BrokerCheckNew: lazy(() => import('pages/agentcert/BrokerCheck')),
  BrokerConfirmNew: lazy(() => import('pages/agentcert/BrokerConfirm')),
  BrokerAipartner: lazy(() => import('pages/agentcert/BrokerAipartner')),
  BrokerAipartnerInviteCeo: lazy(() => import('pages/agentcert/BrokerAipartnerInviteCeo')),
};

const posts = {
  AllPosts: lazy(() => import('pages/allposts/AllPosts')),
  Post: lazy(() => import('pages/allposts/Post')),
};

const my = {
  MyBoardCoAgency: lazy(() => import('pages/common/MyboardCoAgency')),
  MyBoardBoard: lazy(() => import('pages/common/MyboardBoard')),
  MyBoardLookingFor: lazy(() => import('pages/common/MyboardLookingfor')),
  MyBoardComment: lazy(() => import('pages/common/MyboardComment')),
  MyInfo: lazy(() => import('pages/myinfo/MyInfo')),
  MyJoiningGroup: lazy(() => import('pages/myinfo/MyJoiningGroup')),
};

const bookmark = {
  BookmarkCoAgency: lazy(() => import('pages/bookmark/BookmarkCoAgency')),
  BookmarkLookingfor: lazy(() => import('pages/bookmark/BookmarkLookingfor')),
  BookmarkBoard: lazy(() => import('pages/bookmark/BookmarkBoard')),
};

const offers = {
  CoAgency: lazy(() => import('pages/coagency/CoAgency')),
  CoAgencyLookingFor: lazy(() => import('pages/coagency/CoAgencyLookingfor')),
  CoAgencyRegister: lazy(() => import('pages/coagency/CoAgencyRegister')),
  CoAgencyEdit: lazy(() => import('pages/coagency/CoAgencyEdit')),
  CoAgencyLFRegister: lazy(() => import('pages/coagency/CoAgencyLFRegister')),
  CoAgencyLFEdit: lazy(() => import('pages/coagency/CoAgencyLFEdit')),
};

const groups = {
  LocalGroup: lazy(() => import('pages/localgroup/LocalGroup')),
  LocalGroupMake: lazy(() => import('pages/localgroup/LocalGroupMake')),
  LocalGroupMakeSelect: lazy(() => import('pages/localgroup/LocalGroupMakeSelect')),
  Group: lazy(() => import('pages/mygroup/Group')),
  MyGroup: lazy(() => import('pages/mygroup/MyGroup')),
  MyGroupDetail: lazy(() => import('pages/mygroup/MyGroupDetail')),
  ApprovalWaiting: lazy(() => import('pages/mygroup/group/ApprovalWaiting')),
  BoardNotice: lazy(() => import('pages/mygroup/group/Boardnotice')),
  BoardPosts: lazy(() => import('pages/mygroup/group/Boardposts')),
  Coagency: lazy(() => import('pages/mygroup/group/Coagency')),
  InAdminSettingConditon: lazy(() => import('pages/mygroup/group/InAdminSettingConditon')),
  InAdminSettingIntrodution: lazy(() => import('pages/mygroup/group/InAdminSettingIntrodution')),
  InAdminSettingManagementApplication: lazy(() => import('pages/mygroup/group/InAdminSettingManagementApplication')),
  InAdminSettingManagementLeader: lazy(() => import('pages/mygroup/group/InAdminSettingManagementLeader')),
  InAdminSettingName: lazy(() => import('pages/mygroup/group/InAdminSettingName')),
  InAdminSettingPanalty: lazy(() => import('pages/mygroup/group/InAdminSettingPanalty')),
  InAdminSettingPlist: lazy(() => import('pages/mygroup/group/InAdminSettingPlist')),
  InAdminSettingPublic: lazy(() => import('pages/mygroup/group/InAdminSettingPublic')),
  InAdminSettingReportedPost: lazy(() => import('pages/mygroup/group/InAdminSettingReportedPost')),
  InAdminSettingTransferringLeader: lazy(() => import('pages/mygroup/group/InAdminSettingTransferringLeader')),
  InJoin: lazy(() => import('pages/mygroup/group/InJoin')),
  Invite: lazy(() => import('pages/mygroup/group/Invite')),
  Join: lazy(() => import('pages/mygroup/group/Join')),
  Member: lazy(() => import('pages/mygroup/group/Member')),
  MyPostMyGroup: lazy(() => import('pages/mygroup/group/Post')),
  SettingAdmin: lazy(() => import('pages/mygroup/group/SettingAdmin')),
  SettingMy: lazy(() => import('pages/mygroup/group/SettingMy')),
};

const system = {
  Parking: lazy(() => import('pages/Parking')),
  NotFound: lazy(() => import('pages/default/NotFound')),
};

/**
 * @description routes array
 * @Class
 * @category App Router
 * @subcategory Router
 */
const routes: protectedRouteObject[] = [
  // 메인
  { index: true, component: posts.AllPosts, protect: true },
  // 검색 결과
  { path: '/search', component: Search, protect: true },

  // 중개사 인증
  { path: PATHAUTH.BROKERNEW, protect: true, component: broker.AuthNew },
  { path: PATHAUTH.BROKERCHECKNEW, protect: true, component: broker.BrokerCheckNew },
  { path: PATHAUTH.BROKER_CONFIRMNEW, protect: true, component: broker.BrokerConfirmNew },
  { path: PATHAUTH.BROKER_AIPARTNER, protect: true, component: broker.BrokerAipartner },
  { path: PATHAUTH.BROKER_AIPARTNER_INVITE_CEO, protect: true, component: broker.BrokerAipartnerInviteCeo },

  // 전체 게시판
  { path: PATHALLPOSTS.MAIN, protect: true, component: posts.AllPosts },
  { path: PATHALLPOSTS.FREEBOARD, protect: true, component: posts.AllPosts },
  { path: PATHALLPOSTS.ISSUE, protect: true, component: posts.AllPosts },
  { path: PATHALLPOSTS.POST, protect: true, component: posts.Post },

  // 공동중개
  { path: PATHCOAGENCY.MAIN, protect: true, component: offers.CoAgency, isBroker: true },
  { path: PATHCOAGENCY.MAINLOOKINGFOR, protect: true, component: offers.CoAgencyLookingFor, isBroker: true },
  { path: PATHCOAGENCY.REGISTER, protect: true, component: offers.CoAgencyRegister, isBroker: true },
  { path: PATHCOAGENCY.EDIT, protect: true, component: offers.CoAgencyEdit, isBroker: true },
  { path: PATHCOAGENCY.LFREGISTER, protect: true, component: offers.CoAgencyLFRegister, isBroker: true },
  { path: PATHCOAGENCY.LFEDIT, protect: true, component: offers.CoAgencyLFEdit, isBroker: true },

  // 모임
  { path: '/mygroup', protect: true, component: groups.MyGroup },
  { path: '/localgroup', protect: true, component: groups.LocalGroup },
  { path: '/localgroupmake', protect: true, component: groups.LocalGroupMake },
  { path: PATHLOCALROUP.MAKESELECT, protect: true, component: groups.LocalGroupMakeSelect },
  { path: PATHMYGROUP.MAIN, protect: true, component: groups.MyGroup },
  { path: PATHMYGROUP.GROUP, protect: true, component: groups.Group },
  { path: PATHMYGROUP.APPROVALWATING, protect: true, component: groups.ApprovalWaiting },
  { path: PATHMYGROUP.BOARDNOTICE, protect: true, component: groups.BoardNotice },
  { path: PATHMYGROUP.BOARDPOSTS, protect: true, component: groups.BoardPosts },
  { path: PATHMYGROUP.COAGENCY, protect: true, component: groups.Coagency },
  { path: PATHMYGROUP.INADMINSETTINGCONDITION, protect: true, component: groups.InAdminSettingConditon },
  { path: PATHMYGROUP.INADMINSETTINGINSTRUCTION, protect: true, component: groups.InAdminSettingIntrodution },
  {
    path: PATHMYGROUP.INADMINSETTINGMANAGEMENTAPPLICATION,
    protect: true,
    component: groups.InAdminSettingManagementApplication,
  },
  { path: PATHMYGROUP.INADMINSETTINGMANAGEMENTLEADER, protect: true, component: groups.InAdminSettingManagementLeader },
  { path: PATHMYGROUP.INADMINSETTINGNAME, protect: true, component: groups.InAdminSettingName },
  { path: PATHMYGROUP.INADMINSETTINGPANALTY, protect: true, component: groups.InAdminSettingPanalty },
  { path: PATHMYGROUP.INADMINSETTINGPLIST, protect: true, component: groups.InAdminSettingPlist },
  { path: PATHMYGROUP.INADMINSETTINGPUBLIC, protect: true, component: groups.InAdminSettingPublic },
  { path: PATHMYGROUP.INADMINSETTINGREPORTEDPOST, protect: true, component: groups.InAdminSettingReportedPost },
  {
    path: PATHMYGROUP.INADMINSETTINGTRANSFETTINGLEADER,
    protect: true,
    component: groups.InAdminSettingTransferringLeader,
  },
  { path: PATHMYGROUP.INJOIN, protect: true, component: groups.InJoin },
  { path: PATHMYGROUP.INVITE, protect: true, component: groups.Invite },
  { path: PATHMYGROUP.JOIN, protect: true, component: groups.Join },
  { path: PATHMYGROUP.MEMBER, protect: true, component: groups.Member },
  { path: PATHMYGROUP.POST, protect: true, component: groups.MyPostMyGroup },
  { path: PATHMYGROUP.SETTINGADMIN, protect: true, component: groups.SettingAdmin },
  { path: PATHMYGROUP.SETTINGMY, protect: true, component: groups.SettingMy },
  { path: PATHMYGROUP.DETAIL, protect: true, component: groups.MyGroupDetail },

  // 내 정보
  { path: PATHMYINFO.MYINFO, protect: true, component: my.MyInfo },
  { path: PATHMYINFO.MYJOININGGROUP, protect: true, component: my.MyJoiningGroup },

  // 내가 쓴 글
  { path: PATHCOMMON.MYBOARDCOAGENCY, protect: true, component: my.MyBoardCoAgency },
  { path: PATHCOMMON.MYBOARDBOARD, protect: true, component: my.MyBoardBoard },
  { path: PATHCOMMON.MYBOARDLOOKINGFOR, protect: true, component: my.MyBoardLookingFor },
  { path: PATHCOMMON.MYBOARDCOMMENT, protect: true, component: my.MyBoardComment },

  // 북마크
  { path: PATHCOMMON.BOOKMARKCOAGENCY, protect: true, component: bookmark.BookmarkCoAgency },
  { path: PATHCOMMON.BOOKMARKLOOKINGFOR, protect: true, component: bookmark.BookmarkLookingfor },
  { path: PATHCOMMON.BOOKMARKBOARD, protect: true, component: bookmark.BookmarkBoard },

  // Parking
  { path: PATHCOMMON.PARKING, protect: false, component: system.Parking },
];

export default routes;
