import { ISelectbox, PostType } from '../@types/allpost';

export const IS_PRODUCTION = import.meta.env.PROD;
export const IS_DEV = import.meta.env.DEV;

export const ALERT_MESSAGE = {
  REQUIRED: '필수 입력값입니다.',
};

export const TYPE_DATAS: ISelectbox[] = [
  { name: '자유 게시판', value: PostType.FreeBorad },
  { name: '부동산 이슈', value: PostType.Issue },
];
