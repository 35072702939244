import { PURGE } from 'redux-persist';
import { createSlice } from '@reduxjs/toolkit';
import type { IUser, IMyinfo } from '@types';

interface IIUser {
  token?: string;
  is_possible_report?: boolean | null;
  currentUser: IUser;
  me: IMyinfo;
  brokerVerified: boolean | null;
  ceoVerified: boolean;
}

const initialState: IIUser = {
  token: '',
  is_possible_report: null,
  currentUser: {
    is_possible_report: null,
    createdAt: null,
    updatedAt: null,
    ceo_verified_at: null,
    area_info: null,
    birthday: null,
    broker_public_at: null,
    company: null,
    company_id: null,
    email: null,
    gender: null,
    grade: null,
    id: null,
    loginType: null,
    login_id: null,
    n_member_code: null,
    name: null,
    nickname: null,
    phone: null,
    phone_public_at: null,
    point_level: null,
    profile_photo_path: null,
    verify: null,
  },
  me: {
    area_info: null,
    birthday: null,
    ceo_verified: false,
    broker_public_at: null,
    company: null,
    company_id: null,
    email: null,
    gender: null,
    grade: null,
    group_post_comment_count: null,
    group_post_count: null,
    id: null,
    loginType: null,
    login_id: null,
    n_member_code: null,
    name: null,
    nickname: null,
    offering_complete_count: null,
    offering_count: null,
    phone: null,
    phone_public_at: null,
    post_comment_count: null,
    post_count: null,
    total_comment_count: null,
    total_like_count: null,
    total_post_count: null,
    verify: null,
    point_level: null,
    profile_photo_path: null,
    totalBroker: null,
  },
  brokerVerified: null,
  ceoVerified: false,
};

/**
 * @description user slice
 * @Class
 * @category redux
 * @subcategory slice
 */
const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    setUser: (state, action) => {
      state.token = action.payload.token;
      state.is_possible_report = action.payload.is_possible_report;
      state.currentUser = action.payload.user;
      state.currentUser.is_possible_report = action.payload.is_possible_report;
    },
    setMe: (state, action) => {
      state.me = action.payload;
    },
    setVerified: (state, action) => {
      state.brokerVerified = action.payload;
    },
    getCeoVerified: (state, action) => {
      state.ceoVerified = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(PURGE, () => initialState);
  },
});

export const { setUser, setMe, setVerified, getCeoVerified } = userSlice.actions;

export default userSlice;
