/* eslint-disable @typescript-eslint/no-shadow */
import { useEffect, useMemo, useState } from 'react';

import * as store from 'stores';

import RouteIdentifier from 'routes/components/RouteIdentifier';
import defaultRoutes from 'routes/default-routes';
import routes from 'routes/routes';

import { useDispatch } from 'react-redux';
import { RootState, useAppSelector } from 'redux/store';

import network from 'utils/network';
import GaTracker from 'pages/GaTracker';
import GTMTracker from 'pages/GTMTracker';

import { commonAxios } from 'utils/commonAxios';
import useIsMounted from 'hooks/useIsMounted';
import type { IGroupState, IMixedKeyValue } from '@types';
// config
import * as Config from 'configs/configs';

export const initGroupState = {
  isReload: false,
  mode: 'write',
  gid: null,
  postId: null,
  modalPos: 'card',
  sharesItems: {
    shareTitle: '',
    shareContent: '',
  },
};

/**
 * @description 리액트 프로젝트의 라우터를 return하는 App F.C
 * @Class
 * @category App Router
 * @subcategory Router
 * @example
 * return (
 *   <RouteIdentifier routes={appRoutes} />
 * )
 * @returns {JSX.Element}
 */
const App = (): JSX.Element => {
  const isMounted = useIsMounted();
  const dispatch = useDispatch();
  const isLogin = useAppSelector((state: RootState) => state.login.isLogin);
  const [groupState, setGroupState] = useState<IGroupState>(initGroupState);
  const [appInfo, setAppInfo] = useState({
    api_status: true,
    api_message: '',
    api_period: '',
  });

  /**
   * @description app정보 가져오기
   */
  useEffect(() => {
    const abortController = new AbortController();
    if (isMounted()) {
      const getData = async () => {
        await getAppInfo();
      };
      getData();
    }
    return () => abortController.abort();
  }, []);

  /**
   * @description 앱 정보가져오기
   * @memberof App
   * @alias getAppInfo
   * @returns {Promise<void>}
   */
  const getAppInfo = async (): Promise<void> => {
    try {
      const joinPath = `${Config.API_URL.GET_APP_INFO}/1.0.0`;
      const res = await commonAxios.get(joinPath);
      const { status, data } = res;
      if (status === 200) {
        console.log('res getAppInfo', res);
        const _data = data.data;
        setAppInfo({
          api_status: _data.api_status,
          api_message: _data.api_message,
          api_period: _data.api_period,
        });
      }
    } catch (err) {
      console.error(err);
    }
  };

  /**
   * @description 라우터 data가져오기
   * @returns {boolean}
   */
  const getRoutes = ({ data, isLogin = false }: { data: IMixedKeyValue[]; isLogin?: boolean }) => {
    return data.filter((route: IMixedKeyValue) => {
      if (route.protect) {
        return isLogin;
      }
      if (route.publicOnly) {
        return !isLogin;
      }
      return true;
    });
  };

  const appRoutes = useMemo(() => getRoutes({ data: [...routes, ...defaultRoutes], isLogin }), [isLogin]);

  /**
   * @description Ga Tracker
   * @returns {void}
   */
  GaTracker();

  /**
   * @description GTM Tracker
   * @returns {void}
   */
  GTMTracker();
  return <RouteIdentifier routes={appRoutes} groupState={groupState} setGroupState={setGroupState} appInfo={appInfo} />;
};

export default App;
