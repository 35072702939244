import { makeAutoObservable, toJS } from 'mobx';
import { ICoAgencyRegisterState, TOffers } from '@types';
import { locStore, getOfferGbnStr } from 'utils/common';
import * as _ from 'lodash';
import { initCoAgencyRegisterState } from '../pages/coagency/InitState';
import { TImportOffers } from '../@types/offers';

class OfferStore {
  offers = locStore('offers') ?? [];
  offeringsSeq: number | null = null;
  importOffers = locStore('importOffers') ?? [];
  importOffersScList = locStore('importOffersScList') ?? [];

  constructor() {
    makeAutoObservable(this);
  }

  setImportOffers(lists: TOffers.TImportOffers[]) {
    this.importOffers = convertImportOffers(lists);
    locStore('importOffers', this.importOffers);
  }

  setImportOffersScList(lists: TOffers.TImportOffersScList[]) {
    this.importOffersScList = convertImportOffersScList(lists);
    locStore('importOffersScList', this.importOffersScList);
  }

  setOfferingsSeq(offeringsSeq: number | null) {
    this.offeringsSeq = offeringsSeq;
  }

  get convertRegister() {
    console.log('test', toJS(this.importOffers), this.offeringsSeq);
    console.log(_.find(toJS(this.importOffers), { offeringsSeq: this.offeringsSeq }));
    return _.find(toJS(this.importOffers), { offeringsSeq: this.offeringsSeq });
  }
}

const convertImportOffers = (data: TOffers.TImportOffers[]) => {
  return data.map((item: TOffers.TImportOffers) => {
    const targetSourceName = item.targetSourceName === 'mst' ? '매물장' : '매물광고';
    return {
      ...item,
      targetSourceName,
    };
  });
};

const convertImportOffersScList = (data: TOffers.TImportOffersScList[]) => {
  return data.map((item: TOffers.TImportOffersScList) => {
    const { acDongNm, areaCd, complexCd, offeringsGbn, cmComplexNm } = item;
    const label = [getOfferGbnStr(offeringsGbn), '>', acDongNm, cmComplexNm].join(' ').trim();
    const dataSet = {
      'data-sc-area-cd': areaCd,
      'data-sc-complex-cd': complexCd,
      'data-sc-offerings-gbn': offeringsGbn,
    };
    return {
      label,
      dataSet: { ...dataSet },
    };
  });
};

const offerStore = new OfferStore();

export { offerStore };
