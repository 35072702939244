import { memo, Suspense, lazy, useEffect } from 'react';
import { Routes, Route, Navigate, useLocation, useSearchParams, useNavigate } from 'react-router-dom';
import ProtectedRoute from 'routes/components/ProtectedRoute';
import PublicRoute from 'routes/components/PublicRoute';
import Layout from 'components/layout/Layout';
import Parking from 'pages/Parking';
// redux
import { useAppSelector } from 'redux/store';
import type { RootState } from 'redux/store';
import { SSO_PATHS } from 'utils/path';

const NotFound = lazy(() => import('pages/default/NotFound'));

/**
 * @description RouteIdentifier
 * @Class
 * @category App Router
 * @subcategory Router
 * @param {Props} props
 * @param {array} props.routes
 * @param {object} props.groupState
 * @param {object} props.appInfo
 * @returns {JSX.Element}
 */
const RouteIdentifier = ({
  routes,
  groupState,
  setGroupState,
  appInfo,
}: {
  routes: any[];
  groupState?: any;
  setGroupState?: any;
  appInfo: any;
}) => {
  const isLogin = useAppSelector((state: RootState) => state.login.isLogin);

  const [searchParams] = useSearchParams();
  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    const isAlarmParameter = searchParams.get('isDetail') || searchParams.get('aipartner');
    const isLocalDev = import.meta.env.MODE === 'localdev';

    if (!isLogin && isAlarmParameter && !isLocalDev) {
      navigate(`${SSO_PATHS.LOGIN}?to=${location.pathname + location.search}`);
    }
  }, []);

  return (
    <Suspense fallback={null}>
      <Routes>
        <Route path="*" element={isLogin ? <NotFound /> : <Navigate to="/" />} />
        <Route element={appInfo && !appInfo.api_status ? <Parking appInfo={appInfo} /> : <Layout />}>
          {routes &&
            routes
              .filter((route) => !route.noLayout)
              .map((route, rIndex) => {
                return (
                  <Route
                    path={route.path}
                    key={`r.${rIndex}`}
                    {...(route.index && { index: route.index })}
                    element={
                      route.protect ? (
                        <ProtectedRoute
                          {...(groupState && { groupState })}
                          {...(setGroupState && { setGroupState })}
                          component={route.component}
                          route={route}
                          location={location}
                        />
                      ) : (
                        <PublicRoute
                          component={route.component}
                          {...(route.isRestrict && { isRestrict: route.isRestrict })}
                        />
                      )
                    }
                  />
                );
              })}
        </Route>
        {routes &&
          routes
            .filter((route) => route.noLayout)
            .map((route, rIndex) => {
              return (
                <Route
                  path={route.path}
                  key={`nolayout.${rIndex}`}
                  element={
                    <PublicRoute
                      component={route.component}
                      {...(route.isRestrict && { isRestrict: route.isRestrict })}
                    />
                  }
                />
              );
            })}
      </Routes>
    </Suspense>
  );
};
export default memo(RouteIdentifier);
