import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import TagManager from 'react-gtm-module';

export const GTM_CLASS: any = {
  NonLogin: {
    home: {
      aiPartner: 'gnb_nl',
      aiPlus: 'gnb_nl',
      login: 'gnb_nl',
      join: 'gnb_signin_nl',
      nowStart: 'start_nl',
    },
  },
  Login: {
    home: {
      myGroup: 'gnb_mygroup',
      logo: 'gnb_netlogo',
      localGroup: 'gnb_localgroup',
      alarm: 'gnb',
      aiPartner: 'gnb',
      aiPlus: 'gnb',
      allLivelyMeeting: 'groupdetail',
      livelyMeeting1: 'detail_1-1',
      livelyMeeting2: 'detail_1-2',
      livelyMeeting3: 'detail_1-3',
      livelyMeeting4: 'detail_1-4',
      allNewMeeting: 'detail_2',
      newMeeting1: 'detail_2-1',
      newMeeting2: 'detail_2-2',
      newMeeting3: 'detail_2-3',
      newMeeting4: 'detail_2-4',
      coagency: 'gnb_coagency',
      board: 'gnb_freeboard',
      makeMeeting: 'select',
    },
    ourMeeting: {
      settingLocal: 'setting_local',
      makeGroup: 'groupmakeselect',
    },
    createMeeting: {
      hobby: 'hobby',
      local: 'local',
      coagency: 'coagency',
    },
    createHobbyMeeting: {
      confirm: 'hobby_complete',
      cancel: 'hobby_cancel',
    },
    createOurMeeting: {
      confirm: 'local_complete',
      cancel: 'local_cancel',
    },
    createCoagencyMeeting: {
      confirm: 'coagency_complete',
      cancel: 'coagency_cancel',
    },
    freeBoard: {
      write: 'freeboardwrite',
      floatingWrite: 'freeboard',
    },
    realEstateIssues: {
      write: 'issuewrite',
      floatingWrite: 'issue',
    },
    boardComplete: {
      close: 'write',
      complete: 'write_complete',
    },
    sharedProperty: {
      tabShare: 'coagency_share',
      tabLookingFor: 'coagency_lookingfor',
      write: 'share_wirte',
      category: 'share_filter_category',
      deal: 'share_filter_deal',
      price: 'share_filter_price',
      price2: 'share_filter_price2',
      room: 'share_filter_room',
      toilet: 'share_filter_toilet',
      address1: 'share_filter_address1',
      address2: 'share_filter_address2',
      address3: 'share_filter_address3',
      address4: 'share_filter_address4',
      area: 'share_filter_area',
      area2: 'share_filter_area2',
      area3: 'share_filter_area3',
      select: 'share_filter_search',
      search: 'share_search',
      close: 'share_close',
      share: 'share',
    },
    createSharedProperty: {
      load: 'share_wirte_aipartner',
      name: 'share_wirte_name',
      tel: 'share_wirte_tel',
      memo: 'share_wirte_memo',
      close: 'share_wirte_close',
      save: 'share_wirte_save',
    },
    loadProperty: {
      close: 'share_wirte_aipartner_close',
      note: 'coagnecy_share_wirte_aipartner_note',
      ad: 'coagnecy_share_wirte_aipartner_ad',
      direct: 'share_wirte_aipartner_direct',
      copy: 'share_wirte_aipartner_copy',
      close2: 'share_wirte_aipartner_close2',
    },
    directRegister: {
      close: 'share_wirte_aipartner_direct',
      cancel: 'share_wirte_aipartner_direct',
      save: 'share_wirte_aipartner_direct',
    },
    lookingForProperty: {
      write: 'lookingfor_wirte',
      address1: 'lookingfor_filter_address1',
      address2: 'lookingfor_filter_address2',
      address3: 'lookingfor_filter_address3',
      price: 'lookingfor_filter_price',
      price2: 'lookingfor_share_filter_price2',
      category: 'lookingfor_filter_category',
      deal: 'lookingfor_filter_deal',
      filterSearch: 'lookingfor_filter_search',
      close: 'lookingfor_close',
      search: 'lookingfor_search',
      lookingfor: 'lookingfor',
    },
    createLookingForProperty: {
      name: 'lookingfor_wirte_name',
      tel: 'lookingfor_wirte_tel',
      memo: 'lookingfor_wirte_memo',
      close: 'lookingfor_wirte_close',
      save: 'lookingfor_wirte_save',
    },
  },
};

/**
 * @description GTMTracker
 * @Class
 * @category Tracker
 * @subcategory tracker
 * @returns {void}
 */
const GTMTracker = (): void => {
  const location = useLocation();
  const [loadingGTM, setLoadGTM] = useState<boolean>(false);

  useEffect(() => {
    if (import.meta.env.VITE_GTM) {
      const tagManagerArgs = {
        gtmId: import.meta.env.VITE_GTM,
      };
      if (import.meta.env.PROD) {
        TagManager.initialize(tagManagerArgs);
        setLoadGTM(true);
        console.log('loadGTM');
      }
    }
  }, []);

  useEffect(() => {
    if (loadingGTM) {
      window.dataLayer = window.dataLayer || [];
      window.dataLayer.push({
        event: 'pageview',
        page: location.pathname + location.search,
      });
      console.log('GTM pageview');
    }
  }, [loadingGTM, location]);
};

export default GTMTracker;
