import { IntlProvider } from 'react-intl';
import { observer } from 'mobx-react';
import { langStore } from '../stores';
import { language } from './language';

/**
 * @description LangProvider
 * @Class
 * @category Service
 * @subcategory lang
 * @param {React.ReactNode | any} children
 * @returns {any}
 */
const LangProvider = observer(({ children }: any) => {
  return (
    <IntlProvider locale={langStore.currentLang.locale} messages={language[langStore.currentLang.locale]}>
      {children}
    </IntlProvider>
  );
});

export default LangProvider;
