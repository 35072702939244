import { PURGE } from 'redux-persist';
import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  groupMain: {
    mygroup: [],
    activeGroup: [],
    newGroup: [],
    InfluenceList: [],
  },
  groupDetail: {
    kindListAll: [],
    openListAll: [],
    conditionList: [],
    typeList: [],
    activeList: [],
    newList: [],
    myGroudList: [],
  },
  groupInfo: {},
  groupInfoComments: [],
  groupInfoMe: {},
  permission: {},
  groupState: {
    isReload: false,
    mode: 'write',
    gid: null,
    postId: null,
    modalPos: 'card',
    sharesItems: {
      shareTitle: '',
      shareContent: '',
    },
  },
};

/**
 * @description 내모임 group slice
 * @Class
 * @category redux
 * @subcategory slice
 */
const groupSlice = createSlice({
  name: 'group',
  initialState,
  reducers: {
    setMyGroup: (state, action) => {
      state.groupMain.mygroup = action.payload;
    },
    setActiveGroup: (state, action) => {
      state.groupMain.activeGroup = action.payload;
    },
    setNewGroup: (state, action) => {
      state.groupMain.newGroup = action.payload;
    },
    setInfluenceList: (state, action) => {
      state.groupMain.InfluenceList = action.payload;
    },
    setKindListAll: (state, action) => {
      state.groupDetail.kindListAll = action.payload;
    },
    setOpenListAll: (state, action) => {
      state.groupDetail.openListAll = action.payload;
    },
    setConditionList: (state, action) => {
      state.groupDetail.conditionList = action.payload;
    },
    setTypeList: (state, action) => {
      state.groupDetail.typeList = action.payload;
    },
    setDetailActiveList: (state, action) => {
      state.groupDetail.activeList = action.payload;
    },
    setDetailNewList: (state, action) => {
      state.groupDetail.newList = action.payload;
    },
    setDetailMyGroundList: (state, action) => {
      state.groupDetail.myGroudList = action.payload;
    },
    setGroupInfo: (state, action) => {
      state.groupInfo = action.payload;
    },
    setGroupInfoComments: (state, action) => {
      state.groupInfoComments = action.payload;
    },
    setGroupInfoMe: (state, action) => {
      state.groupInfoMe = action.payload;
    },
    setPermission: (state, action) => {
      state.permission = action.payload;
    },
  },

  extraReducers: (builder) => {
    builder.addCase(PURGE, () => initialState);
  },
});

export const {
  setMyGroup,
  setActiveGroup,
  setNewGroup,
  setInfluenceList,
  setKindListAll,
  setOpenListAll,
  setConditionList,
  setTypeList,
  setDetailActiveList,
  setDetailNewList,
  setDetailMyGroundList,
  setGroupInfo,
  setGroupInfoComments,
  setGroupInfoMe,
  setPermission,
} = groupSlice.actions;

export default groupSlice;
