/**
 * @description PostType
 * @Class
 * @category types
 * @subcategory allpost
 */
export enum PostType {
  FreeBorad = 1500,
  Issue = 1501,
}

/**
 * @description Column
 * @Class
 * @category types
 * @subcategory allpost
 */
export interface Column {
  title: string;
  field: string;
}

/**
 * @description Radio
 * @Class
 * @category types
 * @subcategory allpost
 */
export interface Radio {
  label: string;
  value: string;
}

/**
 * @description PostSearch
 * @Class
 * @category types
 * @subcategory allpost
 */
export interface PostSearch {
  keyword?: string;
}

/**
 * @description PostWrite
 * @Class
 * @category types
 * @subcategory allpost
 */
export interface PostWrite {
  title?: string;
  content?: string | undefined;
  kind?: string;
}

/**
 * @description ISelectbox
 * @Class
 * @category types
 * @subcategory allpost
 */
export interface ISelectbox {
  name: string;
  value: string | number;
}

/**
 * @description PagingOptions
 * @Class
 * @category types
 * @subcategory allpost
 */
export interface PagingOptions {
  page_size: number;
  page: number;
}

/**
 * @description IComments
 * @Class
 * @category types
 * @subcategory allpost
 */
export interface IComments {
  content: string;
  emoticon: string;
  photos: any[];
}