import axios, { AxiosError, AxiosRequestConfig } from 'axios';
import { persistor } from 'index';
import { rmLocStore, locStore } from '../utils/common';
import confirmErrorService from '../utils/service/confirmErrorService';

const axiosInstance = axios.create();
export interface ResponseBase<T = any> {
  success: boolean;
  message: string;
  data?: T;
}

export type Response = ResponseBase | Error | undefined | any;

axiosInstance.interceptors.request.use(
  (config: any) => {
    const token = localStorage.getItem('token');

    if (token) {
      config.headers = {
        ...config.headers,
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      };
    }

    config.metadata = { startTime: new Date() };
    return config;
  },
  (err) => Promise.reject(err),
);

axiosInstance.interceptors.response.use(
  (response: Response) => {
    const endTime = new Date();
    const { startTime } = response.config.metadata;
    const duration = endTime.getTime() - startTime.getTime();
    const responseDataSize = JSON.stringify(response.data).length;
    // 관련 코드 QA 후 삭제
    console.log(`Performance Request took ${duration} ms`);
    console.log(`Performance Request Data Size: ${response.config.metadata.requestDataSize} bytes`);
    console.log(`Performance Response Data Size: ${responseDataSize} bytes`);
    return response;
  },
  async (error) => {
    const { response } = error as AxiosError;

    if (response && response.status === 401) {
      rmLocStore('token');
      rmLocStore('currentUser');
      persistor.purge(); // 리덕스 초기화
      window.location.reload();
    }

    return Promise.reject(error);
  },
);

const token = locStore('token');

axiosInstance.defaults.baseURL = import.meta.env.VITE_API_BASE_URL;
axiosInstance.defaults.withCredentials = true;
const defaultHeaders: AxiosRequestConfig['headers'] = {
  Accept: 'application/json',
  'Content-Type': 'application/json',
  Authorization: token ? `Bearer ${token}` : undefined,
};

axiosInstance.defaults.headers = defaultHeaders as any;

export default axiosInstance;
