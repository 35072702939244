import { SSO_URL } from '../configs/configs';

/**
 * @description SSO_PATHS
 * @Class
 * @category App Router
 * @subcategory path
 */
export const SSO_PATHS = {
  LOGIN:
    import.meta.env.MODE === 'localdev'
      ? `${SSO_URL}/integrated/login?requestPage=${
          import.meta.env.VITE_SSO_RETURN_URL
        }/integrated/callback&serviceCode=2000`
      : `/integrated/business`,
  SIGNUP: `${SSO_URL}/integrated/join-confirm?requestPage=${import.meta.env.VITE_SSO_RETURN_URL}&serviceCode=2000`,
  SIGNUP_COMPLETE: '/integrated/join-complete',
  INFO: `${SSO_URL}/integrated/member-info?requestPage=${import.meta.env.VITE_SSO_RETURN_URL}/myinfo&serviceCode=2000`,
  WITHDRAW: `${SSO_URL}/integrated/withdraw?requestPage=${
    import.meta.env.VITE_SSO_RETURN_URL
  }/myinfo?requestType=withdraw&serviceCode=2000`,
  BUSINESS: `/integrated/business`,
  CALLBACK: `/integrated/callback`,
};

/**
 * @description PATHAUTH
 * @Class
 * @category App Router
 * @subcategory path
 */
export const PATHAUTH = {
  MAIN: '/',
  LOGIN: '/login',
  SIGNIN: '/signup',
  FORGOT_ID: '/forgot-id',
  FORGOT_PASSWORD: '/forgot-password',
  // 중개사 신규
  BROKERNEW: '/authnew',
  BROKERCHECKNEW: '/authchecknew',
  BROKER_CONFIRMNEW: '/auth/broker-confirmnew',
  BROKER_AIPARTNER: '/auth/broker-aipartner',
  BROKER_AIPARTNER_INVITE_CEO: '/auth/broker-aipartner-invite-ceo',
  // 통합회원 가입완료
  INTERMEMCOMPLETE: '/integrated/join-complete',
};

/**
 * @description PATHALLPOSTS
 * @Class
 * @category App Router
 * @subcategory path
 */
export const PATHALLPOSTS = {
  MAIN: '/allposts',
  POST: '/allposts/Post',
  FREEBOARD: '/allposts/free-board',
  ISSUE: '/allposts/issue',
};

/**
 * @description PATHCOAGENCY
 * @Class
 * @category App Router
 * @subcategory path
 */
export const PATHCOAGENCY = {
  MAIN: '/coagency-coagency',
  MAINLOOKINGFOR: '/coagency-lookingfor',
  REGISTER: '/coagency-register',
  EDIT: '/coagency-edit',
  LFREGISTER: '/coagency-lookingfor-register',
  LFEDIT: '/coagency-lookingfor-edit',
};

/**
 * @description PATHLOCALROUP
 * @Class
 * @category App Router
 * @subcategory path
 */
export const PATHLOCALROUP = {
  MAIN: '/localgroup',
  MAKE: '/localgroupmake',
  MAKESELECT: '/localgroupmakeselect',
};

/**
 * @description PATHMYGROUP
 * @Class
 * @category App Router
 * @subcategory path
 */
export const PATHMYGROUP = {
  MAIN: '/mygroup',
  DETAIL: '/mygroupdetail',
  // 21개 /mygroup/group 페이지
  GROUP: '/mygroup/group',
  APPROVALWATING: '/mygroup/approvalwaiting',
  BOARDNOTICE: '/mygroup/boardnotice',
  BOARDPOSTS: '/mygroup/boardposts',
  COAGENCY: '/mygroup/coagency',
  INADMINSETTINGCONDITION: '/mygroup/inadminsettingconditon',
  INADMINSETTINGINSTRUCTION: '/mygroup/inadminsettingintrodution',
  INADMINSETTINGMANAGEMENTAPPLICATION: '/mygroup/inadminsettingmanagementapplication',
  INADMINSETTINGMANAGEMENTLEADER: '/mygroup/inadminsettingmanagementleader',
  INADMINSETTINGNAME: '/mygroup/inadminsettingname',
  INADMINSETTINGPANALTY: '/mygroup/inadminsettingpanalty',
  INADMINSETTINGPLIST: '/mygroup/inadminsettingplist',
  INADMINSETTINGPUBLIC: '/mygroup/inadminsettingpublic',
  INADMINSETTINGREPORTEDPOST: '/mygroup/inadminsettingreportedpost',
  INADMINSETTINGTRANSFETTINGLEADER: '/mygroup/inadminsettingtransferringleader',
  INJOIN: '/mygroup/injoin',
  INVITE: '/mygroup/invite',
  JOIN: '/mygroup/join',
  MEMBER: '/mygroup/member',
  POST: '/mygroup/grouppost',
  SETTINGADMIN: '/mygroup/settingadmin',
  SETTINGMY: '/mygroup/settingmy',
};

/**
 * @description PATHCOMMON
 * @Class
 * @category App Router
 * @subcategory path
 */
export const PATHCOMMON = {
  BOOKMARKCOAGENCY: '/bookmark-coagency',
  BOOKMARKLOOKINGFOR: '/bookmark-lookingfor',
  BOOKMARKBOARD: '/bookmark-board',
  MYBOARDCOAGENCY: '/myboard-coagency',
  MYBOARDLOOKINGFOR: '/myboard-lookingfor',
  MYBOARDBOARD: '/myboard-board',
  MYBOARDCOMMENT: '/myboard-comment',
  TERMS: '/terms',
  PRIVACY: '/privacy-policy',
  MOBILELOGIN: '/mobile-login',
  MOBILEBRIDGE: '/mobile-bridge',
  PARKING: '/parking',
  NOTFOUND: '*',
  LOGOUT: '/logout',
};

/**
 * @description PATHMYINFO
 * @Class
 * @category App Router
 * @subcategory path
 */
export const PATHMYINFO = {
  MYINFO: '/myinfo',
  MYJOININGGROUP: '/myjoininggroup',
};

/**
 * @description PATHNOTICE
 * @Class
 * @category App Router
 * @subcategory path
 */
export const PATHNOTICE = {
  LIST: '/notice',
  DETAIL: '/notice-detail',
};

/**
 * @description PATHQNAHELP
 * @Class
 * @category App Router
 * @subcategory path
 */
export const PATHQNAHELP = {
  LIST: '/qna',
  DETAIL: '/qna-detail',
  HELP: '/help',
};
