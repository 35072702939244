import { useEffect, useState } from 'react';
import cn from 'classnames';
import Styles from 'styles/layout.module.css';
import CommonStyles from 'styles/common.module.css';

type TDropdown = {
  visibility?: boolean;
  children?: React.ReactNode;
};

/**
 * @description Dropdown F.C
 * @Class
 * @category components
 * @subcategory form
 * @component
 * @param {TDropdown} props
 * @param {boolean} props.visibility
 * @param {React.ReactNode} props.children
 * @returns {JSX.Element}
 */
const Dropdown = ({ visibility, children }: TDropdown) => {
  const [visibilityAnimation, setVisibilityAnimation] = useState(false);
  const [repeat, setRepeat] = useState(null);

  useEffect(() => {
    if (visibility) {
      setRepeat(null);
      setVisibilityAnimation(true);
    } else {
      setTimeout(() => {
        setVisibilityAnimation(false);
        setRepeat(null);
      }, 400);
    }
  }, [visibility]);

  return (
    <article
      className={cn(
        CommonStyles['components-dropdown'],
        Styles['user-menu'],
        Styles['tool-list'],
        visibility ? CommonStyles['slide-fade-in-dropdown'] : CommonStyles['slide-fade-out-dropdown'],
      )}
    >
      {visibilityAnimation && children}
    </article>
  );
};

export default Dropdown;
