/* eslint-disable jsx-a11y/anchor-is-valid */
import cn from 'classnames';

import CommonStyles from 'styles/common.module.css';

interface Props {
  appInfo: {
    api_status: boolean;
    api_message: string;
    api_period: string;
  };
}

/**
 * @description Parking F.C
 * @Class
 * @category Pages
 * @subcategory default
 * @component
 * @returns {JSX.Element}
 */
const Parking = (props: Props): JSX.Element => {
  const { appInfo } = props;
  console.log('appInfo in Parking', appInfo);
  return (
    <div id="wrap">
      <div className={cn(CommonStyles.header, CommonStyles['logout-header'])}>
        <div className={CommonStyles['header-left']}>
          <button type="button" className={CommonStyles.logo}>
            <span className={CommonStyles.a11y_hidden}>이실장넷</span>
          </button>
        </div>
      </div>

      <div className={cn(CommonStyles.container, CommonStyles.parking)}>
        <div className={CommonStyles.content}>
          <i className={CommonStyles['icon-parking']} />
          <h1 className={CommonStyles.tit}>점검 중입니다.</h1>
          <h2 className={CommonStyles.txt}>
            신속하게 처리하여 빠른 시일 내에 정상적인 서비스가 되도록 할게요.
            <br />
            감사합니다.
          </h2>
          <div className={CommonStyles['dot-indent']}>
            <p>
              점검시간 :{' '}
              <b className={CommonStyles['co-dorg']}>
                {appInfo?.api_period}
                {/* 4.27일(토) 18시 ~ 4.28일(일) 02시 //(8시간){' '} */}
              </b>
            </p>
            <p>
              점검내용 : <b> {appInfo?.api_message}</b>
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Parking;
