import { combineReducers, configureStore } from '@reduxjs/toolkit';
import { useDispatch, useSelector } from 'react-redux';
import storage from 'redux-persist/lib/storage';
import { persistReducer } from 'redux-persist';

import globalSlice from './slices/global';
import loginSlice from './slices/login';
import userSlice from './slices/user';
import coagencySlice from './slices/coagency';
import groupSlice from './slices/group';
import offerSlice from './slices/offer';
import loadingSlice from './slices/loading';

/**
 * @description reducers // combine reducers
 * @Class
 * @category redux
 * @subcategory store
 */
const reducers = combineReducers({
  global: globalSlice.reducer,
  login: loginSlice.reducer,
  user: userSlice.reducer,
  coagency: coagencySlice.reducer,
  group: groupSlice.reducer,
  offer: offerSlice.reducer,
  loading: loadingSlice.reducer,
});

/**
 * @description persist config  // 이곳에 whitelist 또는 blacklist를 array로 설정
 * @Class
 * @category redux
 * @subcategory store
 */
const persistConfig = {
  key: 'root',
  storage,
  whitelist: ['global', 'login', 'user', 'coagency', 'group', 'offer'],
};

/**
 * @description persisted Reducer
 * @Class
 * @category redux
 * @subcategory store
 */
const persistedReducer = persistReducer(persistConfig, reducers);

/**
 * @description Redux store 설정
 * @Class
 * @category redux
 * @subcategory store
 */
const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) => getDefaultMiddleware({ serializableCheck: false }),
  devTools: !import.meta.env.PROD,
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
export const useAppDispatch = () => useDispatch();
export const useAppSelector = useSelector;

export default store;
