/* src/RouteChangeTracker.js */
import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import ReactGA from 'react-ga4';

import { useAppSelector, RootState } from 'redux/store';

/**
 * @description uri가 변경될 때마다 pageview 이벤트 전송
 * @Class
 * @category Tracker
 * @subcategory tracker
 * @returns {void}
 */
const GaTracker = (): void => {
  const location = useLocation();
  const [initialized, setInitialized] = useState(false);

  const currentUser = useAppSelector((state: RootState) => state.user.currentUser);

  // 구글 애널리틱스 운영서버만 적용
  useEffect(() => {
    if (import.meta.env.VITE_GOOGLE_ANALYTICS) {
      ReactGA.initialize(import.meta.env.VITE_GOOGLE_ANALYTICS);
      setInitialized(true);
    }
  }, []);

  // location 변경 감지시 pageview 이벤트 전송
  useEffect(() => {
    if (initialized) {
      ReactGA.set({ page: location.pathname });
      ReactGA.send('pageview');
    }
  }, [initialized, location]);

  // userId 연결
  useEffect(() => {
    if (initialized && currentUser) {
      ReactGA.set({ userId: currentUser?.id });
    }
  }, [initialized, currentUser]);
};

export default GaTracker;

/**
 * @description Ga login
 * @Class
 * @category Tracker
 * @subcategory tracker
 * @returns {Promise<void>}
 */
export const GaLogin = async (): Promise<void> => {
  try {
    ReactGA.event({
      category: 'member',
      action: 'login',
      label: 'login',
      value: 0,
    });
  } catch (e) {
    console.error(e);
  }
};

/**
 * @description Ga Event
 * @Class
 * @category Tracker
 * @subcategory tracker
 * @param {string} category
 * @param {string} action
 * @param {string} label
 * @param {number} value
 * @returns {Promise<void>}
 */

export const GaEvent = async (category: string, action: string, label: string, value: number): Promise<void> => {
  try {
    ReactGA.event({
      category,
      action,
      label,
      value,
    });
  } catch (e) {
    console.error(e);
  }
};
