import { makeAutoObservable, toJS } from 'mobx';
import { locStore, rmLocStore } from '../utils/common';
import { responseBody, signInResponseBody, meResponseBody } from '../@types';

class AuthStore {
  token = locStore('token');

  isLogin = !!this.token;

  currentUser: meResponseBody = locStore('currentUser');

  constructor() {
    makeAutoObservable(this);
  }

  getCurrentUser = () => {
    return locStore('currentUser');
  };

  setCurrentUser = (data: responseBody<signInResponseBody>, callback = null) => {
    const { token, user } = data.data;
    if (token) {
      locStore('token', token);
      locStore('currentUser', user);
      this.token = token;
      this.isLogin = true;
      this.currentUser = { ...this.currentUser, ...this.getCurrentUser() };
    }
  };

  setCurrentUserInPage = (data: responseBody<signInResponseBody>, callback = null) => {
    console.log('setCurrentUserInPage data', data);
    this.isLogin = true;
    locStore('currentUser', data);
    this.currentUser = { ...this.currentUser, ...this.getCurrentUser() };
  };

  setCurrentUserReport = (data: responseBody<signInResponseBody>, callback = null) => {
    console.log('setCurrentUserReport data', data);
    const possibleReport = { is_possible_report: data };
    this.currentUser = {
      ...this.currentUser,
      ...this.getCurrentUser(),
      ...possibleReport,
    };
    locStore('currentUser', this.currentUser);
  };

  resetCurrentUser = () => {
    this.isLogin = false;
    this.currentUser = {
      createdAt: '',
      email: '',
      id: 0,
      login_id: '',
      loginId: '',
      updatedAt: '',
      area_info: {},
      is_possible_report: false,
    };
    this.token = null;
    rmLocStore('token');
    rmLocStore('currentUser');
  };

  setToken = (token: string) => {
    this.token = token;
  };
}

const authStore = new AuthStore();

export { authStore };
