import { lazy } from 'react';
import { SSO_PATHS, PATHNOTICE, PATHQNAHELP, PATHCOMMON } from 'utils/path';
import { DEFAULT_PATHS } from 'configs/configs';
import { publicRouteObject } from '../@types/props';

const InterMemComplete = lazy(() => import('pages/default/InterMemComplete'));
const Terms = lazy(() => import('pages/default/Terms'));
const PrivacyPolicy = lazy(() => import('pages/default/PrivacyPolicy'));
const Callback = lazy(() => import('pages/default/Callback'));
const Business = lazy(() => import('pages/default/Business'));

const GnbNotLoggedIn = lazy(() => import('pages/GnbNotLoggedIn'));

const NoticeList = lazy(() => import('pages/notice/NoticeList'));
const NoticeDetail = lazy(() => import('pages/notice/NoticeDetail'));
const QnAList = lazy(() => import('pages/qnaHelp/qnaList'));
const QnADetail = lazy(() => import('pages/qnaHelp/qnaDetail'));
const HelpDesk = lazy(() => import('pages/qnaHelp/HelpDesk'));

// 앱 테스트 (추후 mobile로 이동 해야함)
const MobileLogin = lazy(() => import('pages/common/MobileLogin'));
const MobileBridge = lazy(() => import('pages/common/MobileBridge'));

// notFound
const NotFound = lazy(() => import('pages/default/NotFound'));
// logout page
const Logout = lazy(() => import('pages/LogoutProc'));

/**
 * @description defaultRoutes array
 * @Class
 * @category App Router
 * @subcategory Router
 */
const defaultRoutes: publicRouteObject[] = [
  { index: true, component: GnbNotLoggedIn, publicOnly: true },
  { path: SSO_PATHS.BUSINESS, component: Business },
  { path: SSO_PATHS.CALLBACK, component: Callback, noLayout: true },
  { path: SSO_PATHS.SIGNUP_COMPLETE, component: InterMemComplete, redirect: true },
  { path: PATHNOTICE.LIST, component: NoticeList },
  { path: PATHNOTICE.DETAIL, component: NoticeDetail },
  { path: PATHQNAHELP.LIST, component: QnAList },
  { path: PATHQNAHELP.DETAIL, component: QnADetail },
  { path: PATHQNAHELP.HELP, component: HelpDesk },
  { path: DEFAULT_PATHS.TERMS, component: Terms },
  { path: DEFAULT_PATHS.PRIVACY_POLICY, component: PrivacyPolicy },
  { path: DEFAULT_PATHS.MOBILE_LOGIN, component: MobileLogin, noLayout: true },
  { path: DEFAULT_PATHS.MOBILE_BRIDGE, component: MobileBridge, noLayout: true },
  // { path: PATHCOMMON.NOTFOUND, component: NotFound, noLayout: true },
  { path: PATHCOMMON.LOGOUT, component: Logout, noLayout: true },
];

export default defaultRoutes;
