import { PURGE } from 'redux-persist';
import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  alimCount: 0,
};

/**
 * @description global slice
 * @Class
 * @category redux
 * @subcategory slice
 */
const globalSlice = createSlice({
  name: 'global',
  initialState,
  reducers: {
    getAlimCount: (state, action) => {
      state.alimCount = action.payload;
    },
  },

  extraReducers: (builder) => {
    builder.addCase(PURGE, () => initialState);
  },
});

export const { getAlimCount } = globalSlice.actions;

export default globalSlice;
