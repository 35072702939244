/**
 * @description API_BASE_URL
 * @Class
 * @category CONSTANT
 * @subcategory config
 */
export const API_BASE_URL = import.meta.env.VITE_API_BASE_URL ?? import.meta.env.VITE_API_BASE_URL;

/**
 * @description SSO_URL
 * @Class
 * @category CONSTANT
 * @subcategory config
 */
export const SSO_URL = import.meta.env.PROD ? 'https://www.aipartner.com' : 'https://dwww.aipartner.com';
/**
 * @description SSO_URL_SINGIN
 * @Class
 * @category CONSTANT
 * @subcategory config
 */
export const SSO_URL_SINGIN = import.meta.env.PROD ? 'https://www.aipartner.net' : 'https://dev.aipartner.net';

/**
 * @description SSO_SESSION_URL
 * @Class
 * @category CONSTANT
 * @subcategory config
 */
export const SSO_SESSION_URL = import.meta.env.PROD
  ? 'https://sso.aipartner.com/login.html?agentId=200'
  : 'https://dsso.aipartner.com/login.html?agentId=20';

/**
 * @description SSO_SESSION_EXPIRE
 * @Class
 * @category CONSTANT
 * @subcategory config
 */
export const SSO_SESSION_EXPIRE = import.meta.env.PROD
  ? 'https://sso.aipartner.com/logout.html?agentId=200'
  : 'https://dsso.aipartner.com/logout.html?agentId=20';

/**
 * @description DEFAULT_PATHS
 * @Class
 * @category CONSTANT
 * @subcategory config
 */
export const DEFAULT_PATHS = {
  APP: '/',
  NOT_FOUND: '/not-found',
  LOGOUT: '/logout',
  TERMS: '/terms',
  PRIVACY_POLICY: '/privacy-policy',
  ALLPOSTS: '/allposts',
  COAGENCY: '/coagency',
  LOCALGROUP: '/localgroup',
  MOBILE_LOGIN: '/mobile-login',
  MOBILE_BRIDGE: '/mobile-bridge',
};

/**
 * @description S3_URL
 * @Class
 * @category CONSTANT
 * @subcategory config
 */
export const S3_URL = import.meta.env.VITE_S3_BUCKET_URL ?? '';
/**
 * @description CDN_URL
 * @Class
 * @category CONSTANT
 * @subcategory config
 */
export const CDN_URL = import.meta.env.VITE_CDN_URL ?? '';
/**
 * @description BUCKET_NAME
 * @Class
 * @category CONSTANT
 * @subcategory config
 */
export const BUCKET_NAME = import.meta.env.VITE_S3_BUCKET_NAME ?? '';

/**
 * @description CRYPTO_SECRET_KEY
 * @Class
 * @category CONSTANT
 * @subcategory config
 */
export const CRYPTO_SECRET_KEY = import.meta.env.VITE_CRYPTO_SECRET_KEY ?? '';

/**
 * @description DEFAULT_SETTINGS
 * @Class
 * @category CONSTANT
 * @subcategory config
 */
export const DEFAULT_SETTINGS = {
  LANGUAGE: 'ko-KR',
};

/**
 * @description SIREN_POPUP_URL
 * @Class
 * @category CONSTANT
 * @subcategory config
 */
export const SIREN_POPUP_URL = 'https://pcc.siren24.com/pcc_V3/jsp/pcc_V3_j10_v2.jsp';

/**
 * @description 서비스를 사용할것인지 config에서 control (예: 지도서비스는 베타시 disabled) SERVICE_USE_SETTING
 * @Class
 * @category CONSTANT
 * @subcategory config
 */
export const SERVICE_USE_SETTING = {
  WRITING_MAP_SERVICE: 'disabled', // 글쓰기 모달에서 지도 include 유무 ( disabled, enabled)
  JOIN_REQUEST_COMMUNITY: 'disabled', // 가입신청중인 모임 ( disabled, enabled)
  MYINFO_BROKER_INFO_PUBLIC: 'disabled', // 내정보보기의 중개사 공개여부
  MYINFO_PHONE_PUBLIC: 'disabled', // 내정보보기의 휴대폰 공개여부
  USE_CO_LEADER_SETTING: 'disabled', // 공동모임장 사용여부
  USE_STATE_DISPLAY_COAGENCY_REGISTER: 'disabled', // 공동중개 매물등록시 상태표시여부
  USE_BROKER_VERIFY_LEGACY: 'enabled', // 사용중인 이살장 중개사 정보연동
  USE_IMPORT_OFFER: 'enabled', // 이실장 매물 불러오기
};

/**
 * @description 공동중개 FOR_SALE_AGENCY_TYPE
 * @Class
 * @category CONSTANT
 * @subcategory config
 */
export const FOR_SALE_AGENCY_TYPE = [
  { name: '내 매물', checked: false },
  { name: '공유 매물', checked: false },
];

/**
 * @description 공동중개 ORDERBY_SELECT_LIST
 * @Class
 * @category CONSTANT
 * @subcategory config
 */
export const ORDERBY_SELECT_LIST = [
  { label: '최신순', value: 'by_recent' },
  { label: '추천순', value: 'by_recommend' },
];

/**
 * @description 공동중개 LIMIT_SELECT_LIST
 * @Class
 * @category CONSTANT
 * @subcategory config
 */
export const LIMIT_SELECT_LIST = [
  { label: '20', value: '20' },
  { label: '50', value: '50' },
  { label: '100', value: '100' },
];

/**
 * @description 공동중개 COAGENCY_DETAIL_TAB_MINE
 * @Class
 * @category CONSTANT
 * @subcategory config
 */
export const COAGENCY_DETAIL_TAB_MINE = [
  { id: 1, title: '상세정보', refName: 'refDetailInfo' },
  { id: 2, title: '비공개 정보', refName: 'refNonePublic' },
  { id: 3, title: '공유 정보', refName: 'refPublic' },
  { id: 4, title: '댓글', refName: 'refViewOfComment' },
];

/**
 * @description 공동중개 COAGENCY_DETAIL_TAB_PUBLIC
 * @Class
 * @category CONSTANT
 * @subcategory config
 */
export const COAGENCY_DETAIL_TAB_PUBLIC = [
  { id: 1, title: '상세정보', refName: 'refDetailInfo' },
  { id: 2, title: '지도보기', refName: 'refMapInfo' },
  { id: 3, title: '중개업소 정보', refName: 'refAgencyInfo' },
  { id: 4, title: '댓글', refName: 'refViewOfComment' },
];

/**
 * @description 공동중개 COAGENCY_LOOKINGFOR_TAB_PUBLIC
 * @Class
 * @category CONSTANT
 * @subcategory config
 */
export const COAGENCY_LOOKINGFOR_TAB_PUBLIC = [
  { id: 1, title: '상세정보', refName: 'refDetailInfo' },
  { id: 3, title: '중개업소 정보', refName: 'refAgencyInfo' },
  { id: 4, title: '댓글', refName: 'refViewOfComment' },
];

/**
 * @description 공동중개 SEARCH_CONDITIONS
 * @Class
 * @category CONSTANT
 * @subcategory config
 */
export const SEARCH_CONDITIONS = [
  { id: 1, type: 'for_sale_type', selected: false },
  { id: 2, type: 'transaction_type', selected: false },
];

/**
 * @description 공동중개 REType
 * @Class
 * @category CONSTANT
 * @subcategory config
 */
export const REType = [
  {
    main_nm: '아파트·오피스텔',
    main_cd: 'A',
    offer_types: [
      { main_cd: 'A', sub_nm: '아파트', sub_cd: 'AP' },
      { main_cd: 'A', sub_nm: '주상복합', sub_cd: 'JS' },
      { main_cd: 'A', sub_nm: '오피스텔', sub_cd: 'OP' },
      { main_cd: 'A', sub_nm: '재개발', sub_cd: 'RB' },
      { main_cd: 'A', sub_nm: '재건축', sub_cd: 'RC' },
    ],
  },
  {
    main_nm: '분양권',
    main_cd: 'B',
    offer_types: [
      { main_cd: 'B', sub_nm: '아파트 분양권', sub_cd: 'AB' },
      { main_cd: 'B', sub_nm: '주상복합 분양권', sub_cd: 'JB' },
      { main_cd: 'B', sub_nm: '오피스텔 분양권', sub_cd: 'OB' },
    ],
  },
  {
    main_nm: '빌라·주택',
    main_cd: 'C',
    offer_types: [
      { main_cd: 'C', sub_nm: '빌라·연립', sub_cd: 'VL' },
      { main_cd: 'C', sub_nm: '단독·다가구', sub_cd: 'DD' },
      { main_cd: 'C', sub_nm: '전원·농가', sub_cd: 'JW' },
      { main_cd: 'C', sub_nm: '한옥', sub_cd: 'HO' },
      { main_cd: 'C', sub_nm: '상가주택', sub_cd: 'SJ' },
    ],
  },
  {
    main_nm: '원룸',
    main_cd: 'D',
    offer_types: [{ main_cd: 'D', sub_nm: '원룸', sub_cd: 'OR' }],
  },
  {
    main_nm: '상가·업무\n공장·토지',
    main_cd: 'E',
    offer_types: [
      { main_cd: 'E', sub_nm: '사무실', sub_cd: 'SM' },
      { main_cd: 'E', sub_nm: '상가점포', sub_cd: 'SP' },
      { main_cd: 'E', sub_nm: '공장·창고', sub_cd: 'GJ' },
      { main_cd: 'E', sub_nm: '지식산업센터', sub_cd: 'JC' },
      { main_cd: 'E', sub_nm: '토지·임야', sub_cd: 'TJ' },
    ],
  },
  {
    main_nm: '건물',
    main_cd: 'F',
    offer_types: [
      { main_cd: 'F', sub_nm: '빌딩·건물', sub_cd: 'BD' },
      { main_cd: 'F', sub_nm: '상가건물', sub_cd: 'SG' },
      { main_cd: 'F', sub_nm: '숙박·콘도', sub_cd: 'CO' },
      { main_cd: 'F', sub_nm: '기타', sub_cd: 'GT' },
    ],
  },
];

/**
 * @description 공동중개 COAGENCY_REG_OFFER_GBN
 * @Class
 * @category CONSTANT
 * @subcategory config
 */
export const COAGENCY_REG_OFFER_GBN = [
  { id: 1, label: '매매', value: 'S' },
  { id: 2, label: '전세', value: 'L' },
  { id: 3, label: '월세', value: 'M' },
  { id: 4, label: '단기임대', value: 'T' },
];

/**
 * @description 공동중개 priceSelect
 * @Class
 * @category CONSTANT
 * @subcategory config
 */
export const priceSelect = [
  { cd: 0, name: '전체', code: 'all_prc' },
  { cd: 1, name: '매매가', code: 'sell_prc' },
  { cd: 2, name: '전세가', code: 'lease_prc' },
  { cd: 3, name: '보증금', code: 'deposit_prc' },
  { cd: 4, name: '월세', code: 'monthly_prc' },
];

/**
 * @description 공동중개 areaSelect
 * @Class
 * @category CONSTANT
 * @subcategory config
 */
export const areaSelect = [
  { cd: 1, name: '공급', code: 'SUP' },
  { cd: 2, name: '계약', code: 'CON' },
  { cd: 3, name: '전용', code: 'EXC' },
  { cd: 4, name: '대지', code: 'LOT' },
  { cd: 5, name: '연', code: 'TOT' },
  { cd: 6, name: '건축', code: 'BUL' },
];

/**
 * @description 공동중개 roomSelect
 * @Class
 * @category CONSTANT
 * @subcategory config
 */
export const roomSelect = [
  { cd: 0, name: '전체', code: null },
  { cd: 1, name: '1개', code: 1 },
  { cd: 2, name: '2개', code: 2 },
  { cd: 3, name: '3개', code: 3 },
  { cd: 4, name: '4개이상', code: 4 },
];

/**
 * @description 공동중개 bathRoomSelect
 * @Class
 * @category CONSTANT
 * @subcategory config
 */
export const bathRoomSelect = [
  { cd: 0, name: '전체', code: null },
  { cd: 1, name: '1개', code: 1 },
  { cd: 2, name: '2개', code: 2 },
  { cd: 3, name: '3개', code: 3 },
  { cd: 4, name: '4개이상', code: 4 },
];

/**
 * @description 공동중개 adStatusGbn
 * @Class
 * @category CONSTANT
 * @subcategory config
 */
export const adStatusGbn = [
  { cd: '00', name: '대기' },
  { cd: '01', name: '검증중' },
  { cd: '10', name: '정보확인' },
  { cd: '20', name: '등본확인' },
  { cd: '50', name: '노출' },
  { cd: '80', name: '전송실패' },
  { cd: '99', name: '종료' },
];

/**
 * @description 구합니다. REKindType
 * @Class
 * @category CONSTANT
 * @subcategory config
 */
export const REKindType = [
  {
    kind: 'A', // 매물구분, A:주거용, B:상업용
    code: 'A01', // 매물유형 코드
    name: '아파트/주상복합', // 매물유형 이름
  },
  {
    kind: 'A',
    code: 'A02',
    name: '오피스텔',
  },
  {
    kind: 'A',
    code: 'A03',
    name: '빌라/연립',
  },
  {
    kind: 'A',
    code: 'A04',
    name: '단독/다가구',
  },
  {
    kind: 'A',
    code: 'A05',
    name: '상가주택',
  },
  {
    kind: 'A',
    code: 'A06',
    name: '원룸',
  },
  {
    kind: 'A',
    code: 'A07',
    name: '재개발',
  },
  {
    kind: 'B',
    code: 'B01',
    name: '사무실',
  },
  {
    kind: 'B',
    code: 'B02',
    name: '상가점포',
  },
  {
    kind: 'B',
    code: 'B03',
    name: '공장/창고',
  },
  {
    kind: 'B',
    code: 'B04',
    name: '지식산업센터',
  },
  {
    kind: 'B',
    code: 'B05',
    name: '토지임야',
  },
  {
    kind: 'B',
    code: 'B06',
    name: '빌딩/건물',
  },
  {
    kind: 'B',
    code: 'B07',
    name: '상가건물',
  },
  {
    kind: 'B',
    code: 'B08',
    name: '숙박/콘도',
  },
  {
    kind: 'B',
    code: 'B09',
    name: '기타',
  },
];

/**
 * @description 구합니다. REKindTypeA
 * @Class
 * @category CONSTANT
 * @subcategory config
 */
export const REKindTypeA = [
  {
    kind: 'A', // 매물구분, A:주거용, B:상업용
    code: 'A01', // 매물유형 코드
    name: '아파트/주상복합', // 매물유형 이름
  },
  {
    kind: 'A',
    code: 'A02',
    name: '오피스텔',
  },
  {
    kind: 'A',
    code: 'A03',
    name: '빌라/연립',
  },
  {
    kind: 'A',
    code: 'A04',
    name: '단독/다가구',
  },
  {
    kind: 'A',
    code: 'A05',
    name: '상가주택',
  },
  {
    kind: 'A',
    code: 'A06',
    name: '원룸',
  },
  {
    kind: 'A',
    code: 'A07',
    name: '재개발',
  },
];

/**
 * @description 도움말 HELP_CATEGORY
 * @Class
 * @category CONSTANT
 * @subcategory config
 */
export const HELP_CATEGORY = [
  {
    key: 1111, // 대분류 code
    label: '전체', // 대분류 이름
  },
  {
    key: 2401, // 대분류 code
    label: '회원정보', // 대분류 이름
  },
  {
    key: 2402,
    label: '커뮤니티',
  },
  {
    key: 2403,
    label: '공동중개',
  },
  {
    key: 2404,
    label: '기타문의',
  },
];

/**
 * @description 온라인 문의 QNA_CATEGORY
 * @Class
 * @category CONSTANT
 * @subcategory config
 */
export const QNA_CATEGORY = [
  {
    main_code: 2501, // 대분류 code
    sub_code: 250101, // 중분류 code
    main_name: '일반상담', // 대분류
    sub_name: '계정관리', // 중분류
  },
  {
    main_code: 2502,
    sub_code: 250201,
    main_name: '커뮤니티',
    sub_name: '모임개설',
  },
  {
    main_code: 2502,
    sub_code: 250202,
    main_name: '커뮤니티',
    sub_name: '모임운영',
  },
  {
    main_code: 2502,
    sub_code: 250203,
    main_name: '커뮤니티',
    sub_name: '게시글/댓글',
  },
  {
    main_code: 2503,
    sub_code: 250301,
    main_name: '공동중개',
    sub_name: '공유매물',
  },
  {
    main_code: 2503,
    sub_code: 250302,
    main_name: '공동중개',
    sub_name: '구합니다',
  },
  {
    main_code: 2504,
    sub_code: 250401,
    main_name: '기타문의',
    sub_name: '기타',
  },
];

/**
 * @description 온라인 문의 COMPOSE_QNA_CATEGORY
 * @Class
 * @category CONSTANT
 * @subcategory config
 */
export const COMPOSE_QNA_CATEGORY = [
  {
    main_code: 2501, // 대분류 code
    main_name: '일반상담', // 대분류
    sub_cate: [
      {
        main_code: 2501, // 대분류 code
        sub_code: 250101, // 중분류 code
        main_name: '일반상담', // 대분류
        sub_name: '계정관리', // 중분류
      },
    ],
  },
  {
    main_code: 2502,
    main_name: '커뮤니티',
    sub_cate: [
      {
        main_code: 2502,
        sub_code: 250201,
        main_name: '커뮤니티',
        sub_name: '모임개설',
      },
      {
        main_code: 2502,
        sub_code: 250202,
        main_name: '커뮤니티',
        sub_name: '모임운영',
      },
      {
        main_code: 2502,
        sub_code: 250203,
        main_name: '커뮤니티',
        sub_name: '게시글/댓글',
      },
    ],
  },
  {
    main_code: 2503,
    main_name: '공동중개',
    sub_cate: [
      {
        main_code: 2503,
        sub_code: 250301,
        main_name: '공동중개',
        sub_name: '공유매물',
      },
      {
        main_code: 2503,
        sub_code: 250302,
        main_name: '공동중개',
        sub_name: '구합니다',
      },
    ],
  },
  {
    main_code: 2504,
    main_name: '기타문의',
    sub_cate: [
      {
        main_code: 2504,
        sub_code: 250401,
        main_name: '기타문의',
        sub_name: '기타',
      },
    ],
  },
];

/**
 * @description 내정보보기 MYINFO_GRADE
 * @Class
 * @category CONSTANT
 * @subcategory config
 */
export const MYINFO_GRADE = [
  { id: 0, label: '새싹', color: '#14ba85' },
  { id: 1, label: '열매', color: '#fbc001' },
  { id: 2, label: '초수', color: '#9191a1' },
  { id: 3, label: '중수', color: '#417be6' },
  { id: 4, label: '고수', color: '#ff8616' },
  { id: 5, label: '영웅', color: '#e802c9' },
];

const covers = Array.from({ length: 21 }, (_, i) => i + 1);

/**
 * @description 우리동네모임 기본 covers GROUP_DEFAULT_COVERS
 * @Class
 * @category CONSTANT
 * @subcategory config
 */
export const GROUP_DEFAULT_COVERS = covers.map((v) => {
  return {
    file_type: 'photo',
    mime_type: 'image/png',
    org_name: `cover_${v}.png`,
    url: `${S3_URL}/temp/cover/cover_${v}.png`,
    cdn_url: `${CDN_URL}/temp/cover/cover_${v}.png`,
  };
});

/**
 * @description API_URL
 * @Class
 * @category CONSTANT
 * @subcategory config
 */
export const API_URL = {
  /** 모임 */
  // 통합api
  POST_GROUP_UNION_MAIN: '/groups/list/main',
  POST_GROUPDETAIL_UNION_MAIN: '/groups/list/main', // 상세로 parameter만 다름
  // 통합api end

  GET_GROUP_BASICINFO: '/groups/:groupId/basicinfo', // 내부사용
  GET_MY_GROUP: '/groups/list/me',
  GET_LIST_ACTIVE: '/groups/list/auth?o=new&r=4',
  GET_LIST_NEW: '/groups/list/auth?r=4&m=7',
  GET_LIST_SIX: '/groups/list/auth?ta[]=TOWN&r=6&o=new',
  GET_ACTIVE_TEN: '/groups/list/auth?k=1103&o=new&r=10',
  GET_LIST_NEW_TEN: '/groups/list/auth?k=1103&r=10&m=7',
  GET_GROUP_KIND_LIST: '/groups/config',
  GET_GROUP_INFO: '/groups/:groupId', // 내부사용

  GET_GROUPS_LIST_MAIN_SECTION: '/groups/list/main/section/:main_section', // 그룹리스트 메인 (main_section값은 I)

  // 모임- 모임상세
  GET_LIST_THREE: '/groups/list/auth?o=rank&r=3',

  // 모임 - post and notice
  GET_POST_LIST: '/groups/{groupId}/posts/list', // 내부사용
  GET_POST_NOTICE_LIST: '/groups/{groupId}/posts/list', // 내부사용

  // 그룹 모임게시글 상세가져오기
  GET_GROUP_POST: '/groups/:groupId/posts/:groupPostId', // 내부사용

  // 지역검색
  GET_LOCAL: '/area?q={keyword}&page=1', // 로컬검색 내부

  // 모임초대코드
  GET_GROUP_INVITE_CODE: '/groups/{groupId}/invite/url', // 내부사용

  PUT_REPORT_GROUP: '/groups/{groupId}/posts/{groupPostId}/report', // 모임 게시글 신고하기 내부사용
  DELETE_REPORT_GROUP: '/groups/{groupId}/posts/{groupPostId}/report/{groupPostReportId}', // 모임 게시글 신고해제 내부사용
  PUT_BOOKMARK_GROUP_POST: '/groups/:groupId/posts/:groupPostId/bookmark', // 모임 게시글 북마크 등록 내부사용
  DELETE_BOOKMARK_GROUP_POST: '/groups/:groupId/posts/:groupPostId/bookmark/:groupPostBookmarkId', // 모임 게시글 북마크 해제 내부사용

  PUT_GROUP_NOTICE: '/groups/:groupId/posts/:groupPostId/notice', // 모임 게시글 공지 등록
  PUT_GROUP_NOTICE_REMOVE: '/groups/:groupId/posts/:groupPostId/notice/remove', // 모임 게시글 공지 등록해제
  PUT_GROUP_IMPORTANT_NOTICE: '/groups/:groupId/posts/:groupPostId/important_notice', // 모임 게시글 중요공지 등록
  PUT_GROUP_IMPORTANT_NOTICE_REMOVE: '/groups/:groupId/posts/:groupPostId/important_notice/remove', // 모임 게시글 중요공지 등록해제

  POST_GROUP_MEMBER_PENALTY: '/groups/:groupId/users/:userId/penalty', // 모임 회원 페널티 등록
  PUT_GROUP_MEMBER_PENALTY: '/groups/:groupId/users/:userId/penalty/clear', // 모임 회원 페널티 해제

  PUT_GROUP_WITHDRAWAL: '/groups/:groupId/users/:userId/withdrawal', // 모임탈퇴  내부사용

  /** 전체게시판 */
  GET_POST_LISTS: '/posts/list', // 전체게시판 게시글 목록 가져오기

  /** 공동중개 */
  // 공동, 비공동 공통
  GET_RE_TYPE: '/offers/area/search', // 매물구분 리스트
  GET_RE_TYPE_ALL: '/offers/type/list', // 매물구분 및 유형목록 함께 가져오기
  POST_ADDR_LIST: '/offers/area/search', // 해당주소검색
  GET_OFFERS_TYPE_INFO: '/offers/type/info', // 면적, 층 정보가져오기
  GET_OWNER_TYPE: '/offers/relation/list', // 비공개정보 관계
  GET_OFFERS_MYGROUP: '/offers/mygroups', // 나의 공동중개 모임목록 가져오기 (향후 중개사 인증모임결과로 변경)

  // 비공동주택 등록
  POST_NONAPARTMENT: '/offers/nonapartment', // 비공동주택 매물등록

  // 비공동주택 면적정보확인
  POST_OFFERS_BUILDINGINFO: '/offers/info/buildingInfo', // 빌딩정보
  POST_OFFERS_FLOORINFO: '/offers/info/floorInfo', // 층정보
  POST_OFFERS_AREAINFO: '/offers/info/areaInfo', // 면적정보

  // 공동주택 단지, 등록
  GET_OFFERS_COMPLEXES: '/offers/complexes', // 단지정보가져오기
  POST_APARTMENT: '/offers/apartment', // 공동주택 매물등록

  // 매물검색
  POST_OFFERS_SEARCH: '/offers/search', // 매물전체검색 및 내가쓴글, 북마크에서 매물검색(body값 다름)
  POST_OFFERS_SEARCH_GROUPID: '/offers/search/:groupId', // 매물모임검색
  POST_OFFERS_UPDATE_DATE: '/offers/update/date', // 수정일갱신,

  // 관심매물등록 및 해제
  POST_OFFERS_BOOKMARK: '/offers/:offerId/bookmark', // 관심매물등록 (북마크)
  POST_DELETE_OFFERS_BOOKMARK: '/offers/bookmark', // 관심매물등록 해제 (북마크) params = { ids: [bookmarkId] }
  POST_BATCH_OFFERS_BOOKMARK: '/offers/bookmark', // 관심매물 일괄 해제

  // 매물상세
  GET_OFFERS_DETAIL: '/offers/:offerId', // 매물상세 (향후 비공동에서 확인): 내부사용

  // 매물상세 댓글
  POST_OFFERINGS_COMMENTS: '/offers/:offeringId/comments', // 공유매물상세 댓글작성 : 내부사용
  PUT_OFFERINGS_COMMENTS: '/offers/:offeringId/comments/:offeringCommentId', // 공유매물상세 댓글수정
  DELETE_OFFERINGS_COMMENTS: '/offers/:offeringId/comments/:offeringCommentId', // 공유매물상세 댓글삭제
  GET_OFFERINGS_LIST: '/offers/:offeringId/comments/list', // 공유매물상세 댓글목록
  POST_OFFERINGS_COMMENTS_KIND: '/offers/:offeringId/comments/:offeringCommentId/action', // 공유매물상세 댓글, 좋아요, 싫어요.
  POST_OFFERINGS_COMMENTS_REPORT: '/offers/:offeringId/comments/:offeringCommentId/report', // 공유매물상세 댓글 신고,
  DELETE_OFFERINGS_COMMENTS_REPORT: '/offers/:offeringId/comments/:offeringCommentId/report/:offeringCommentReportId', // 공유매물상세 댓글 신고해제

  // 수정 ( 비공동, 공동 )
  PUT_OFFERS_NONAPARTMENT: '/offers/nonapartment/:id', // 비공동 수정
  PUT_OFFERS_APARTMENT: '/offers/apartment/:id', // 공동 수정

  // 삭제
  DELETE_APARTMENT_OFFERS: '/offers/apartment/:id', // 공동삭제 : 내부사용
  DELETE_NONAPARTMENT_OFFERS: '/offers/nonapartment/:id', // 비공동삭제 : 내부사용

  // 거래완료처리
  PUT_OFFERS_COMPLETE: '/offers/:offerId/complete', // 거래완료 : 내부사용

  // 공유매물 싱세수정요청
  GET_IMPROVE_OFFERING: '/offers/improve/reason', // 매물 수정요청 사유목록
  POST_IMPROVE_OFFERING: '/offers/improve/:offeringId', // 매물 수정요청 등록
  DELETE_IMPROVE_OFFERING: '/offers/improve/:offeringId', // 매물 수정요청 해제
  POST_IMPROVE_STOP_SHARE: '/offers/improve/:offeringId/share', // 매물 수정요청 공유중지

  // ********** 구합니다. **********/
  GET_SEEKS_TYPE_LIST: '/seeks/type/list', // 매물유형 가져오기
  GET_SEEKS_RELATION_LIST: '/seeks/relation/list', // 고객관계목록 가져오기

  POST_SEEK: '/seeks', // 등록
  POST_SEEKS_SEARCH: '/seeks/search', // 검색
  GET_SEEKS_SEEKID: '/seeks/:seekId', // 상세 //내부사용
  PUT_SEEKS_SEEKID: '/seeks/:seekId', // 수정 //내부사용
  DELETE_SEEKS_SEEKID: '/seeks/:seekId', // 삭제 //내부사용
  PUT_COMPLETE_SEEKS_SEEKID: '/seeks/:seekId/complete', // 거래완료처리 // 내부사용

  POST_SEEK_BOOKMARK: '/seeks/:seekId/bookmark', // 관심등록 (북마크) // 내부사용
  POST_DELETE_SEEK_BOOKMARK: '/seeks/bookmark', // 관심등록 헤제 (북마크)  // 내부사용 params = { ids: [bookmarkId] }
  POST_BATCH_SEEK_BOOKMARK: '/seeks/bookmark', // 구합니다. 관심매물 일괄 해제

  POST_SEEK_SEARCH_GROUPID: '/seeks/search/:groupId', // 구합니다 검색모임
  POST_SEEK_SEARCH_MY_SHARE: '/seeks/search', // 검색 (공유구함 & 내구함) //파라미터 다름
  POST_SEEK_SEARCH_BOOKMARK: '/seeks/search', // 검색 (관심)

  // 구합니다. 댓글
  POST_SEEKS_COMMENTS: '/seeks/:offeringSeekId/comments', // 구합니다. 댓글작성 : 내부사용
  PUT_SEEKS_COMMENTS: '/seeks/:offeringSeekId/comments/:offeringSeekCommentId', // 구합니다. 댓글수정
  DELETE_SEEKS_COMMENTS: '/seeks/:offeringSeekId/comments/:offeringSeekCommentId', // 구합니다. 댓글삭제
  GET_SEEKS_LIST: '/seeks/:offeringSeekId/comments/list', // 구합니다. 댓글목록
  POST_SEEKS_COMMENTS_KIND: '/seeks/:offeringSeekId/comments/:offeringSeekCommentId/action', // 구합니다. 댓글, 좋아요, 싫어요.
  POST_SEEKS_COMMENTS_REPORT: '/seeks/:offeringSeekId/comments/:offeringSeekCommentId/report', // 구합니다. 댓글 신고,
  DELETE_SEEKS_COMMENTS_REPORT:
    '/seeks/:offeringSeekId/comments/:offeringSeekCommentId/report/:offeringSeekCommentReportId', // 구합니다. 댓글 신고해제

  // ****** 고객지원 ******/
  POST_NOTICE_LIST: '/support/notice/list', // 공지사항 리스트
  GET_NOTICE_ITEM: '/support/notice/:noticeId', // 공지사항 상세 //내부 사용

  // 도움말
  GET_FAQ_CATEGORY: '/support/faq/cate', // 도움말 카테고리(대분류) 목록
  POST_FAQ_LIST: '/support/faq/list', // 도움말 검색
  PUT_FAQ_STATS: '/support/faq/:faqId/stats', // 도움말 통계 업데이트  // 내부사용

  // 온라인문의
  GET_QNA_CATEGORY: '/support/qna/cate', // 온라인 문의 카테고리
  POST_QNA_LIST: '/support/qna/list', // 온라인 문의 목록
  GET_QNA_DETAIL: '/support/qna/:qnaId', // 온라인 문의 상세  // 내부사용
  POST_QNA: '/support/qna', // 온라인 문의하기
  PUT_QNA: '/support/qna/:qnaId', // 온라인 문의 수정하기  ( 향후 활용 부분)

  // 공통 layoutinfo
  GET_COMMON_LAYOUTINFO: '/my/layoutinfo',

  // SSO
  POST_SSO_SIGNIN: '/sso/signin', // sso signin
  POST_SSO_SIGNOUT: '/sso/signout', // sso signout
  GET_SSO_ME: '/sso/me', // 내정보 가져오기
  PUT_SSO_PROFILE: '/sso/profile', // 프로파일 변경
  POST_ISNICKNAME: '/sso/isnickname', // 닉네임 중복여부
  POST_PHONE_PUBLIC: '/sso/phonepublic', // 휴대폰 공개여부 토글
  POST_BROKER_PUBLIC: '/sso/brokerpublic', // 중개사 공개여부 변경 (토글)
  PUT_SET_ALIM: '/sso/setalram', // 모임알림관리

  // 중개사 인증 연동
  GET_BROKER_ISCOMPANY: '/broker/iscompany', // 사업자 등록번호 조회
  GET_BROKER_JURIDICAL: '/broker/juridical', // 개설등록번호 조회
  GET_BROKER_JURIDICAL_COMPANY: '/broker/juridical/company', // 개설등록번호 기업조회
  GET_BROKER_JURIDICAL_MEMBER: '/broker/juridical/member', // 개설등록번호 멤버조회
  GET_BROKER_AIPARTNER: '/broker/aipartner', // 이실장조회
  POST_BROKER_COMPANY_REQUEST: '/broker/company', // 개설등록번호 요청
  POST_BROKER_VERIFY: '/broker/verify', // 중개사 인증
  POST_BROKER_VERIFY_AIPARTNER: '/broker/verifyAipartner', // 중개사 인증 (이실장)

  GET_BROKER_LIST: '/broker/list', // 중개사 직원 목록
  POST_BROKER_FIRE: '/broker/fire', // 중개사 직원 해고
  DELETE_BROKER_LEAVE: '/broker/leave', // 중개사 퇴사

  GET_BROKER_VERIFY_CHECK: '/broker/verify/check', // 중개사 인증여부 체크
  GET_BROKER_VERIFY_FIRE_CHECK: '/broker/verify/fire_check/:user_id', // 직원해고 여부 체크

  // 앱 관련
  GET_APP_INFO: '/app/info', // /app/info/:version
};

// GNB 모두읽음 버튼 관련 링크, API 주소 도메인 설정
// const domain = process.env.NODE_ENV === 'development' ? 'dwww' : 'www';
// const apiDomain = process.env.NODE_ENV === 'development' ? 'dapi' : 'api';

// export const urlAipartner = `https://${domain}.aipartner.com/`;
// export const urlPlusIB = `https://${domain}.aipartner.plus/integrated/business`;
// export const urlPlus = `https://${domain}.aipartner.plus`;
// export const urlApi = `https://${apiDomain}.aipartner.net/my/notices/all`;
