import React, { createContext, useContext } from 'react';

import CommonStore from './CommonStore';

const commonstore = {
  commonstore: new CommonStore(),
};

const storesContext = createContext(commonstore);

export function CommonStoreProvider({ children }: { children: React.ReactNode }) {
  return <storesContext.Provider value={commonstore}>{children}</storesContext.Provider>;
}

export const useCommonStore = () => useContext(storesContext);
