import React, {
  createContext,
  useState,
  useRef,
  cloneElement,
  useContext,
  useEffect,
  useCallback,
  isValidElement,
} from 'react';
import Dialog from '@mui/material/Dialog';

import { IS_PRODUCTION } from 'utils/constants';

function consoleErrorWithTrace(message?: any, ...params: any[]) {
  console.error(message, ...params);
  console.trace();
}

interface ModalOption {
  enableBackdropClick?: boolean;
}

type ModalSubmitResult = any;
type ModalOpenFn = (children: React.ReactNode, option?: ModalOption) => Promise<ModalSubmitResult> | false;
export const ModalContext = createContext<ModalOpenFn | null>(null);

const defaultModalOption = {
  enableBackdropClick: false,
};

/**
 * @description Modal Provider
 * @Class
 * @category components
 * @subcategory Modal
 * @component
 * @param {object} props
 * @param {React.ReactNode} props.children
 * @returns {JSX.Element}
 */
export default function ModalProvider({ children }: { children: any }) {
  const [open, setOpen] = useState(false);
  const [modal, setModal] = useState<React.ReactNode | null>(null);
  const [modalOption, setModalOption] = useState<ModalOption>(defaultModalOption);
  const resolverRef = useRef<{ resolve: (value?: unknown) => void }>({
    resolve: () => {
      if (!IS_PRODUCTION) consoleErrorWithTrace('!IS_PRODUCTION');
    },
  });

  const openModal: ModalOpenFn = useCallback((children, option) => {
    setModalOption({
      ...defaultModalOption,
      ...(option ?? {}),
    });

    if (isValidElement(children)) {
      setModal(
        cloneElement(children, {
          ...children.props,
          onClose: handleClose,
          onSubmit: handleSubmit,
        }),
      );
      setOpen(true);

      return new Promise((resolve) => {
        resolverRef.current = {
          resolve,
        };
      });
    }

    return false;
  }, []);

  const handleClose = () => {
    resolverRef.current.resolve();

    setOpen(false);
  };

  const handleSubmit = (params: ModalSubmitResult) => {
    resolverRef.current.resolve(params);

    setOpen(false);
  };

  useEffect(() => {
    function onPop() {
      setOpen(false);
    }
    window.addEventListener('popstate', onPop);

    return () => {
      window.removeEventListener('popstate', onPop);
    };
  }, []);

  return (
    <>
      <ModalContext.Provider value={openModal}>{children}</ModalContext.Provider>

      <Dialog
        componentsProps={{
          root: {
            style: {
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              borderRadius: '12px !important',
              zIndex: 99999,
            },
          },
          backdrop: {
            style: { backgroundColor: 'rgba(0, 0, 0, .4)' },
          },
        }}
        open={open}
        onClose={handleClose}
      >
        {modal}
      </Dialog>
    </>
  );
}

/**
 * @description useModal
 * @Class
 * @category components
 * @subcategory Modal
 * @component
 * @returns {ModalOpenFn}
 */
export function useModal(): ModalOpenFn {
  const context = useContext(ModalContext);

  if (context === null) {
    throw new Error('useModal Error');
  }

  return context;
}
