import { observable, action, makeAutoObservable } from 'mobx';
import { DEFAULT_SETTINGS } from '../configs/configs';

class LangStore {
  currentLang = {
    locale: DEFAULT_SETTINGS.LANGUAGE,
  };

  lang = [
    { value: 'ko-KR', label: '한국어' },
    { value: 'en-US', label: 'EN' },
  ];

  constructor() {
    makeAutoObservable(this);
  }

  setLang = (locale: string) => {
    this.currentLang = {
      locale,
    };
  };
}

const langStore = new LangStore();

export { langStore };
