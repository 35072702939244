import axios, { AxiosError, AxiosRequestConfig } from 'axios';
import { toJS } from 'mobx';

import confirmErrorService from '../utils/service/confirmErrorService';
import { locStore, rmLocStore } from '../utils/common';
import axiosInstance from './axiosInstance';

export interface ResponseBase<T = any> {
  success: boolean;
  message: string;
  data?: T;
}

export type Response = ResponseBase | Error | undefined | any;

/**
 * @description sso Signin ==> 미사용
 * @Class
 * @category used Mobx
 * @subcategory common api
 * @returns {Promise<Response>}
 */
export const ssoSignin = async (): Promise<Response> => {
  const path = '/sso/signin';
  try {
    const { data } = await axiosInstance.post(path);

    return data;
  } catch (error: any) {
    const errorResponse = (error as AxiosError).response;
    console.error(errorResponse?.data);
    return undefined;
  }
};

export const getCommentLists = async (params: any): Promise<Response> => {
  console.log('params', toJS(params));
  const path = `/posts/comments/my`;
  try {
    const { data } = await axiosInstance.post(path, params);

    return data.data;
  } catch (error: any) {
    const errorResponse = (error as AxiosError).response;
    console.error(errorResponse?.data);
    return undefined;
  }
};

export const getCommentListsMygroup = async (params: any): Promise<Response> => {
  console.log('params', toJS(params));
  const path = `/posts/comments/my/group`;
  try {
    const { data } = await axiosInstance.get(path, {
      params,
    });

    return data.data;
  } catch (error: any) {
    const errorResponse = (error as AxiosError).response;
    console.error(errorResponse?.data);
    return undefined;
  }
};

export const postWrite = async (params: any): Promise<Response> => {
  const path = '/posts';
  try {
    const { data } = await axiosInstance.post(path, params);

    return data;
  } catch (error: any) {
    const errorResponse = (error as AxiosError).response;
    console.error(errorResponse?.data);
    return undefined;
  }
};

export const postReport = async (postId: any, params: any): Promise<Response> => {
  const path = `/posts/${postId}/report`;
  try {
    const { data } = await axiosInstance.post(path, params);

    return data;
  } catch (error: any) {
    const errorResponse = (error as AxiosError).response;
    console.error(errorResponse?.data);
    return undefined;
  }
};

export const modifyWrite = async (params: any): Promise<Response> => {
  const path = '/posts';
  try {
    const { data } = await axiosInstance.put(path, params);

    return data;
  } catch (error: any) {
    const errorResponse = (error as AxiosError).response;
    console.error(errorResponse?.data);
    return undefined;
  }
};

export const DeletePost = async (postId: any): Promise<Response> => {
  const path = `/posts/${postId}`;
  try {
    const { data } = await axiosInstance.delete(path);

    return data;
  } catch (error: any) {
    const errorResponse = (error as AxiosError).response;
    console.error(errorResponse?.data);
    return undefined;
  }
};

export const getSearchDefalutLists = async (key: any): Promise<Response> => {
  // const path = `/groups/list/auth?ta[]=CLUB&o=new&page=1&q=${key}`;
  const path = `/groups/list/auth?o=new&page=1&q=${key}`; // 카테고리 없이 전체가 검색되도록 수정완료

  try {
    const { data } = await axiosInstance.get(path);

    return data.data;
  } catch (error: any) {
    const errorResponse = (error as AxiosError).response;
    console.error(errorResponse?.data);
    return undefined;
  }
};

export const getSearchLocalLists = async (key: any, areaCode: any): Promise<Response> => {
  // const path = `/groups/list/auth?ta[]=TOWN&o=new&a=${areaCode}&page=1&q=${key}`;
  const path = `/groups/list/auth?ta[]=TOWN&ta[]=OFFERING&o=new&a=${areaCode}&page=1&q=${key}`;

  try {
    const { data } = await axiosInstance.get(path);

    return data.data;
  } catch (error: any) {
    const errorResponse = (error as AxiosError).response;
    console.error(errorResponse?.data);
    return undefined;
  }
};
