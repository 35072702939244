import axios, { AxiosRequestConfig, AxiosInstance, AxiosError } from 'axios';
import { API_BASE_URL } from 'configs/configs';
// import { authStore } from 'stores/auth';
import { rmLocStore, locStore } from 'utils/common';
import { persistor } from 'index';

export const commonAxios: AxiosInstance = axios.create({
  baseURL: `${API_BASE_URL}`,
  withCredentials: true,
  timeout: 20000,
});

commonAxios.interceptors.request.use(
  (config: any) => {
    const token = localStorage.getItem('token')!;

    if (token) {
      config.headers = {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: token && `Bearer ${token}`,
      };
    } else {
      config.headers = {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      };
    }

    return config;
  },
  (error) => {
    if (error.response?.status === 401) {
      const { data } = error.response;
      console.log('data in commonAxios', data);
      rmLocStore('token');
      rmLocStore('currentUser');
      persistor.purge(); // 리덕스 초기화
      window.location.reload();
    }
    return Promise.reject(error);
  },
);

commonAxios.interceptors.response.use(
  (response) => {
    return response;
  },

  (error) => {
    if (error.response?.status === 401) {
      const { data } = error.response;
      console.log('data in commonAxios', data);
      rmLocStore('token');
      rmLocStore('currentUser');
      persistor.purge(); // 리덕스 초기화
      window.location.reload();
    }
    return Promise.reject(error);
  },
);
