/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import cn from 'classnames';
import { commonAxios } from 'utils/commonAxios';
import { timeDisplay } from 'utils/common';
import { useCommonStore } from 'stores/common/index';

// redux
import { useAppSelector } from 'redux/store';
import type { RootState } from 'redux/store';

// styles
import Styles from 'styles/layout.module.css';
import CommonStyles from 'styles/common.module.css';
import ResetStyles from 'styles/reset.module.css';
import { IMixedKeyValue } from '@types';

type TAlimPopup = {
  showAlim?: boolean;
  setShowAlim: (showAlim: boolean) => void;
};

type TAlimList = {
  board_cate?: string;
  content?: string;
  display_title: string;
  display_content: string;
  created_at?: string;
  group?: IMixedKeyValue;
  group_id?: number | null;
  id?: number | null;
  notice_type?: number;
  state?: number;
  url?: string;
  verify_date?: string | null;
};

/**
 * @description alim popup F.C
 * @Class
 * @category components
 * @subcategory alim
 * @component
 * @param {TAlimPopup} props
 * @param {boolean} props.showAlim
 * @param {function} props.setShowAlim
 * @returns {JSX.Element}
 */
const AlimPopup = (props: TAlimPopup): JSX.Element => {
  const isLogin = useAppSelector((state: RootState) => state.login.isLogin);
  const { commonstore } = useCommonStore();
  const { showAlim, setShowAlim } = props;

  const [isLoading, setIsLoading] = useState(false);
  const [alimList, setAlimList] = useState([]);
  const [unReadAlimCount, setUnReadAlimCount] = useState<number | null>(null);
  const [readActive, setReadActive] = useState(false);
  // 알림 모두읽음
  const [show, setShow] = useState(true);

  /**
   * @description 알림목록 가져오기
   * @returns {void}
   */
  useEffect(() => {
    if (isLogin) {
      const getData = async () => {
        setIsLoading(true);
        await Promise.allSettled([getMyAlimList(), getUnleadAlimCount()]);
      };
      getData();
    }
  }, [isLogin]);

  /**
   * @description 읽은 후 알림목록과 읽지 않은 알림키운트 가져오기
   * @returns {void}
   */
  useEffect(() => {
    const getData = async () => {
      if (readActive) {
        await Promise.allSettled([getMyAlimList(), getUnleadAlimCount()]);
        setReadActive(false);
      }
    };
    getData();
  }, [readActive]);

  /**
   * @description 알림리스트 목록 api 호출
   * @returns {Promise<void>}
   */
  const getMyAlimList = async (): Promise<void> => {
    await commonAxios
      .get('/my/notices')
      .then((res) => {
        console.log('res getMyAlimList', res);
        if (res && res.status === 200) {
          setAlimList(res.data);
          setIsLoading(false);
        }
      })
      .catch((err) => {
        console.error('error', err);
        setIsLoading(false);
      });
  };

  /**
   * @description 읽지 않은 알림카운트 가져오기
   * @returns {Promise<void>}
   */
  const getUnleadAlimCount = async (): Promise<void> => {
    await commonAxios
      .get('/my/notices/count')
      .then((res) => {
        console.log('res getUnleadAlimCount', res);
        if (res && res.status === 200) {
          setUnReadAlimCount(res.data?.count);
          commonstore.AlimCount(res.data?.count);
        }
      })
      .catch((err) => {
        console.error('error', err);
      });
  };

  /**
   * @description 알림 읽기
   * @param {number | string} noticeId
   * @returns {Promise<void>}
   */
  const handleReadAlim = async (noticeId: number | string): Promise<void> => {
    await commonAxios
      .put(`/my/notices/${noticeId}`)
      .then((res) => {
        console.log('res handleReadAlim', res);
        if (res && res.status === 200) {
          if (res.data.result === 1) {
            setReadActive(true);
          }
        }
      })
      .catch((err) => {
        console.error('error', err);
      });
  };

  /**
   * @description 알림모두 읽기
   * @returns {Promise<void>}
   */
  const handleClick = async (): Promise<void> => {
    await commonAxios
      .put(`${import.meta.env.VITE_AIPARTNER_NET_MNA_URL}`, null, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('token')}`,
        },
      })
      .then((res) => {
        console.log('API 호출 결과', res);
        if (res && res.status === 200) {
          const { data } = res;
          if (data) {
            console.log(data);
            setUnReadAlimCount(0);
            setAlimList([]);
          }
        }
      })
      .catch((err) => {
        console.error('API 호출에 실패했습니다.', err);
        if (err && err.response?.data) {
          console.error(err.response.data.message);
        }
      });
  };

  return (
    <div
      className={cn(CommonStyles['side-fixed-layer'], Styles['alarm-layer'])}
      style={showAlim ? { display: 'block' } : {}}
    >
      <div className={cn(CommonStyles['layer-header'], Styles['layer-header'])}>
        <p className={cn(CommonStyles['layer-title'], Styles['layer-title'])}>
          <i className={CommonStyles['icon-bell']} />
          알림
          <span className={cn(CommonStyles['co-blue'], ResetStyles.sfont500)}>{unReadAlimCount || 0}</span>
        </p>
        <button type="button" className={cn(CommonStyles.btn, Styles.btn)} onClick={handleClick}>
          모두읽음
        </button>

        <button
          type="button"
          onClick={() => setShowAlim(false)}
          className={cn(CommonStyles['close-btn'], Styles['close-btn'])}
        >
          <span className={CommonStyles.a11y_hidden}>닫기</span>
        </button>
      </div>
      <div className={cn(Styles['layer-content'], Styles['custom-scroll'], CommonStyles['custom-scroll'])}>
        {alimList.length ? (
          alimList.map((list: TAlimList, i: number) => {
            const nowDate = new Date();
            return (
              <div key={i} className={cn(Styles['list-wrap'], list.state === 0 ? Styles.new : '')}>
                <Link to={`${list.url}`} className={Styles.list}>
                  <div className={Styles.detail} onClick={() => handleReadAlim(list.id!)}>
                    <p className={cn(Styles.txt)}>
                      <b style={list.display_title?.length > 50 ? { maxWidth: '144px' } : {}}>
                        {list.display_title || ''}
                      </b>{' '}
                      {list.display_content}
                    </p>
                    <div className={cn(CommonStyles['divide-box'], Styles['divide-box'])}>
                      <p className={CommonStyles['limit-line1']}>
                        {list.notice_type === 2
                          ? '새공지'
                          : list.notice_type === 12
                          ? '공유매물'
                          : list.notice_type === 13
                          ? '구합니다'
                          : list.group
                          ? list.group?.name
                          : list.board_cate === '자유게시판' || list.board_cate === '부동산이슈'
                          ? `[ 전체게시판 ] ${list.board_cate}`
                          : ''}
                      </p>
                      <p className={CommonStyles['co-blue']}>{timeDisplay(nowDate, list.created_at!)}</p>
                    </div>
                  </div>
                </Link>
              </div>
            );
          })
        ) : (
          <div className={cn(Styles['list-wrap'], Styles.new)}>
            <div className={CommonStyles.noList}>
              {isLoading ? '알림을 가져오고 있습니다.' : '새로운 알림이 없습니다.'}
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default AlimPopup;
