import { makeObservable, observable, action, computed } from 'mobx';

class AutocompleteStore<T> {
  inputValue = '';

  suggestions: T[] = [];

  constructor() {
    makeObservable(this, {
      inputValue: observable,
      suggestions: observable,
      setInputValue: action,
      setSuggestions: action,
      filteredSuggestions: computed,
    });
  }

  setInputValue(value: string) {
    this.inputValue = value;
  }

  setSuggestions(suggestions: T[]) {
    this.suggestions = suggestions;
  }

  get filteredSuggestions(): T[] {
    return this.suggestions;
  }
}

const autocompleteStore = new AutocompleteStore<object>();

export { autocompleteStore };
