const ko = {
  'lang.loginId': '아이디',
  'lang.loginId.placeholder': '6~12자 이내, 영문/숫자 사용 가능',
  'lang.loginId.required': '6자 이상 12자 이하의 소문자 영문 혹은 소문자 영문과 숫자 조합하여 입력해주세요.',
  'lang.loginId.minLength': '6자 이상 12자 이하의 소문자 영문 혹은 소문자 영문과 숫자 조합하여 입력해주세요.',
  'lang.loginId.maxLength': '6자 이상 12자 이하의 소문자 영문 혹은 소문자 영문과 숫자 조합하여 입력해주세요.',
  'lang.loginId.pattern': '6자 이상 12자 이하의 소문자 영문 혹은 소문자 영문과 숫자 조합하여 입력해주세요.',
  'lang.loginId.already': '이미 {account} 계정으로 가입한 아이디가 있습니다.',

  'lang.idCheck.alert': '아이디 중복 확인을 해주세요.',
  'lang.idCheck.success': '사용할 수 있는 아이디입니다.',
  'lang.loginId.fail': '사용 불가능한 아이디입니다.',

  'lang.password': '비밀번호',
  'lang.password.placeholder': '8자~16자 이내, 문자/숫자/특수문자 사용 가능',
  'lang.password.required': '8자 이상 16자 이내',
  'lang.password.minLength': '8자 이상 16자 이내',
  'lang.password.maxLength': '8자 이상 16자 이내',
  'lang.password.pattern': '영문 / 숫자 / 특수문자 3가지 포함',

  'lang.passwordConfirm': '비밀번호 확인',
  'lang.passwordConfirm.placeholder': '8자~16자 이내, 문자/숫자/특수문자 사용 가능',
  'lang.passwordConfirm.required': '비밀번호가 일치하지 않습니다.',
  'lang.passwordConfirm.confirm': '비밀번호가 일치하지 않습니다.',

  'lang.email': '이메일',
  'lang.email.placeholder': 'exmaple@aipartnet.com',
  'lang.email.required': '이메일 형식이 올바르지 않습니다.',
  'lang.email.format': '이메일 형식이 올바르지 않습니다.',

  'lang.name.placeholder': '이름을 입력해주세요.',
  'lang.name.required.forgot': '가입 시 등록한 이름을 입력해주세요.',

  'lang.phone.placeholder': '휴대폰 번호를 입력해주세요.',
  'lang.phone.required.forgot': '가입 시 등록한 휴대폰 번호를 입력해주세요.',

  'lang.loginId.forgot.placeholder': '아이디를 입력해주세요.',
  'lang.loginId.required.forgot': '가입 시 등록한 아이디를 입력해주세요.',

  'lang.cert.number.placeholder': '인증번호 4자리',
  'lang.cert.number.required': '인증번호를 입력해주세요.',
  'lang.cert.send.success': "인증번호가 발송되었습니다. {m}분 안에 인증번호를 입력해주세요.",
  'lang.cert.resend.deny': '재발송 요청이 너무 빠릅니다. 잠시 후 다시 요청해주세요.',
  'lang.cert.result.timeout': '유효 시간이 만료되었습니다. 재발송 후 다시 시도해 주세요.',
  'lang.cert.result.fail': '인증번호가 일치하지 않습니다.',
  'lang.cert.result.notfound': '가입 시 입력하신 회원정보가 맞는지 다시 한번 확인해주세요.',

  'lang.resetPassword.placeholder': '새 비밀번호를 입력해주세요.',
  'lang.resetPassword_confirm.placeholder': '새 비밀번호를 한 번 더 입력해주세요.',

  'lang.brokerName.required': '중개사명을 입력해주세요.',
  'lang.cropNumber.required': '중개사를 선택해주세요.',
};

export default ko;
