/* eslint-disable import/newline-after-import */
import { ComponentClass, FunctionComponent } from 'react';
import { Navigate } from 'react-router-dom';
import { useAppSelector } from 'redux/store';
import type { RootState } from 'redux/store';
type ComponentType<P = {}> = ComponentClass<P> | FunctionComponent<P>;

type TPublicRoute = {
  isRestrict?: boolean;
  component?: React.ReactNode | ComponentType | React.ReactElement | JSX.Element | React.ReactPortal | any;
  location?: string;
};

/**
 * @description PublicRoute
 * @Class
 * @category App Router
 * @subcategory Router
 */
const PublicRoute = ({ component: Component, ...props }: TPublicRoute) => {
  const isLogin = useAppSelector((state: RootState) => state.login.isLogin);
  return (
    <>
      {isLogin && props?.isRestrict ? (
        <Navigate to="/" state={{ from: props.location }} replace />
      ) : (
        <Component {...props} />
      )}
    </>
  );
};

export default PublicRoute;
