import { makeObservable, action, computed, observable, toJS, runInAction } from 'mobx';
import { toast } from 'react-toastify';

import { PagingOptions } from '../../@types/allpost';

import {
  postWrite,
  DeletePost,
  getCommentLists,
  postReport,
  getSearchDefalutLists,
  getSearchLocalLists,
  getCommentListsMygroup,
  ssoSignin,
} from '../../rest/commonRest';
import confirmSimpleService from '../../utils/service/confirmSimpleService';

type Report = {
  content: string;
};

type Emotion = {
  kind: number;
};

const initialFilters = {
  keyword: '',
};

const pagingOptions = {
  page_size: 10, // 페이지당 노출 건수
  page: 1, // 현재 페이지 수
};

const initialreport: Report = {
  content: '',
};

const emoji = '';

const initialPageData: any = {
  page: '',
};

const initNoticeMessage = {
  id: null,
  title: '',
  content: '',
  created_at: '',
  notice_type: '',
};

export default class CommonStore {
  @observable private _isLoading = false;
  @observable dataList: any[] = [];
  @observable dataListMygroup: any[] = [];
  @observable dataSearchList: any[] = [];
  @observable dataMyList: any[] = [];
  @observable dataMyListMygroup: any[] = [];
  @observable dataCommentList: any[] = [];
  @observable dataCommentListMygroup: any[] = [];
  @observable pagingOptions: PagingOptions;
  @observable emoji: any;
  @observable report: Report;
  @observable onLocal: any;
  @observable group: any;
  @observable keyword: any;
  @observable searchPalcehoder: any;
  @observable dataPage: any;
  @observable alimCount: number;
  @observable noticeMessage: object;
  @observable zoomIndex: any;

  constructor() {
    makeObservable(this);
    this.pagingOptions = pagingOptions;
    this.report = initialreport;
    this.onLocal = '';
    this.searchPalcehoder = '';
    this.group = '';
    this.dataPage = initialPageData;
    this.alimCount = 0;
    this.noticeMessage = initNoticeMessage;
  }

  @computed get isZoomIndex() {
    return this.zoomIndex as any;
  }

  @computed get isDataPage() {
    return this.dataPage as any;
  }

  @computed get iskeyword() {
    return this.keyword as any;
  }

  @computed get isSearchPalcehoder() {
    return this.searchPalcehoder as any;
  }

  @computed get listSearch() {
    return this.dataSearchList as any[];
  }

  @computed get localgroup() {
    return this.group as any;
  }

  @computed get list() {
    return this.dataList as any[];
  }

  @computed get listMygroup() {
    return this.dataListMygroup as any[];
  }

  @computed get listMy() {
    return this.dataMyList as any[];
  }

  @computed get listMyMygroup() {
    return this.dataMyListMygroup as any[];
  }

  @computed get listComment() {
    return this.dataCommentList as any[];
  }

  @computed get listCommentMygroup() {
    return this.dataCommentListMygroup as any[];
  }

  @computed get isLoading() {
    if (this._isLoading) return true;

    return false;
  }

  @computed get countOfAlim() {
    return this.alimCount;
  }

  @computed get noticeLast() {
    return this.noticeMessage;
  }

  @action
  setPage({ name, value }: any) {
    console.log('value', toJS(value));
    runInAction(() => {
      if (name === 'page') {
        this.dataPage = {
          ...this.dataPage,
          [name]: value,
        };
      }
    });
    console.log('commonStore this.dataPage', toJS(this.dataPage));
  }

  @action
  setSearchPalcehoder(value: any) {
    runInAction(() => {
      this.searchPalcehoder = value;
    });
    console.log('this.searchPalcehoder', this.searchPalcehoder);
  }
  @action
  onZoomIndex(idx: any) {
    runInAction(() => {
      this.zoomIndex = idx;
    });
  }
  @action
  setEmoji(src: any) {
    runInAction(() => {
      this.emoji = src;
    });
    console.log('this.emoji', this.emoji);
  }

  @action
  setPagingOptions() {
    runInAction(() => {
      this.pagingOptions.page += 1;
    });
    // this.getLists();
  }

  @action
  setReport({ name, value }: any) {
    runInAction(() => {
      this.report = {
        ...this.report,
        [name]: value,
      };
    });
  }

  @action
  async DeletePost(postId: any) {
    const result = await DeletePost(postId);
    if (result) {
      toast('삭제되었습니다.', {
        autoClose: 3500,
        hideProgressBar: true,
      });
    }
  }

  @action
  async postReport(postId: any) {
    const result = await postReport(postId, this.report);
    if (result) {
      confirmSimpleService.show(
        '',
        <>신고 내용은 이실장넷 이용약관 및 정책에 의해서 처리되며, 허위신고시 서비스 이용이 제한될 수 있습니다.</>,
        {
          confirm: '확인',
        },
      );
    }
  }

  @action
  async postWrite(values: any) {
    console.log('values', toJS(values));
    const result = await postWrite(values);
    if (result) {
      // toast('글 작성되었습니다.', {
      //   autoClose: 3500,
      //   hideProgressBar: true,
      // });
      // this.getLists();
    }
  }

  @action
  async getCommentLists() {
    const data = await getCommentLists(this.pagingOptions);
    console.log('getCommentLists data', data);
    if (data) {
      runInAction(() => {
        this.dataCommentList = data;
      });
    }
  }

  @action
  async getCommentListsMygroup() {
    const data = await getCommentListsMygroup(this.pagingOptions);
    console.log('ggetCommentListsMygroup data', data);
    if (data) {
      runInAction(() => {
        this.dataCommentListMygroup = data;
      });
    }
  }

  @action
  async sendLocal(h: any) {
    console.log('h', toJS(h));
    runInAction(() => {
      this.onLocal = h;
    });
  }

  @action
  async setLocal() {
    runInAction(() => {
      this.group = this.onLocal;
    });
    console.log('this.group', toJS(this.group));
  }

  @action
  async setKeyword(keyword: any) {
    runInAction(() => {
      this.keyword = keyword;
    });
  }

  @action
  async getSearchDefalutLists(searchWord: any) {
    const data = await getSearchDefalutLists(searchWord);
    console.log('getSearchDefalutLists data', data);
    if (data) {
      runInAction(() => {
        this.dataSearchList = data;
      });
    }
    console.log('getSearchDefalutLists', toJS(this.dataSearchList));
  }

  @action
  async getSearchLocalLists(searchWord: any, areaCode: any) {
    const data = await getSearchLocalLists(searchWord, areaCode);
    console.log('getSearchLocalLists data', data);
    if (data) {
      runInAction(() => {
        this.dataSearchList = data;
      });
    }
    console.log('getSearchLocalLists', toJS(this.dataSearchList));
  }

  @action
  async AlimCount(data: number) {
    if (data) {
      runInAction(() => {
        this.alimCount = data;
      });
    }
  }

  @action
  async ssoSignin() {
    const result = await ssoSignin();
    if (result) {
      toast('로그인 되었습니다.', {
        autoClose: 3500,
        hideProgressBar: true,
      });
    }
  }

  @action
  async NoticeLastMessage(data: any) {
    if (data) {
      runInAction(() => {
        this.noticeMessage = data;
      });
    }
  }
}
