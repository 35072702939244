import { PURGE } from 'redux-persist';
import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  isDataLoading: false,
};

/**
 * @description loading slice
 * @Class
 * @category redux
 * @subcategory slice
 */
const loadingSlice = createSlice({
  name: 'loading',
  initialState,
  reducers: {
    onDataLoad: (state, action) => {
      state.isDataLoading = action.payload;
    },
    offDataLoad: (state, action) => {
      state.isDataLoading = action.payload;
    },
  },

  extraReducers: (builder) => {
    builder.addCase(PURGE, () => initialState);
  },
});

export const { onDataLoad, offDataLoad } = loadingSlice.actions;

export default loadingSlice;
