import { PURGE } from 'redux-persist';
import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  address: {
    sido: [],
    guGun: [],
    eupMyeonDong: [],
    li: [],
  },
};

/**
 * @description 공동중개 slice
 * @Class
 * @category redux
 * @subcategory slice
 */
const coagency = createSlice({
  name: 'coagency',
  initialState,
  reducers: {
    setAddress: (state, action) => {
      state.address.sido = action.payload.sido;
      state.address.guGun = action.payload.guGun;
      state.address.eupMyeonDong = action.payload.eupMyeonDong;
      state.address.li = action.payload.li;
    },
  },

  extraReducers: (builder) => {
    builder.addCase(PURGE, () => initialState);
  },
});

export const { setAddress } = coagency.actions;

export default coagency;
